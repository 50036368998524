// /src/context/ThemeContext.tsx

import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';

/** "system", "light" o "dark". */
type Theme = 'system' | 'light' | 'dark';
/** Alias per compatibilità con codice esistente. */
type ThemeColor = 'light' | 'dark';

/**
 * Interfaccia del contesto.
 * NOTA: isDark e themeColor reintrodotti per compatibilità con codice esistente.
 */
interface ThemeContextType {
  theme: Theme;
  setTheme: (newTheme: Theme) => void;
  toggleTheme: () => void;
  isDark: boolean;
  themeColor: ThemeColor;
}

/** Creiamo il contesto con valore iniziale `undefined`. */
const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

/** Chiave localStorage per ricordare la preferenza. */
const STORAGE_KEY = 'theme';
/** Classe CSS da aggiungere a `<html>` se dark. */
const DARK_CLASS = 'dark';

/**
 * Verifica preferenza tema scuro del sistema operativo.
 */
function getSystemThemePreference(): 'light' | 'dark' {
  if (typeof window === 'undefined') return 'dark';
  return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches 
    ? 'dark' 
    : 'light';
}

/**
 * Legge tema da localStorage, preferenze sistema o ritorna "dark" come predefinito.
 */
function getInitialTheme(): Theme {
  if (typeof window === 'undefined') return 'dark';
  
  // Verifica preferenza salvata
  const stored = window.localStorage.getItem(STORAGE_KEY) as Theme | null;
  
  // Se c'è una preferenza salvata e valida, usala
  if (stored === 'light' || stored === 'dark' || stored === 'system') {
    return stored;
  }
  
  // Default a dark (non system, per impostare direttamente dark)
  return 'dark';
}

/**
 * Risolve 'system' nel tema effettivo (light/dark) basato sulle preferenze OS.
 */
function resolveTheme(theme: Theme): 'light' | 'dark' {
  if (theme === 'system') {
    return getSystemThemePreference();
  }
  return theme;
}

/**
 * Palette professionale e raffinata.
 */
function applyColorPalette(themeValue: 'light' | 'dark'): void {
  const root = document.documentElement;
  
  // Transizioni fluide per cambi tema
  root.style.setProperty('--transition-time', '0.5s');
  root.style.setProperty('--transition-props', 'background-color, color, border-color, fill, stroke, box-shadow');
  
  if (themeValue === 'light') {
    // Palette chiara professionale
    root.style.setProperty('--body-bg', '#ffffff');
    root.style.setProperty('--card-bg', '#f8f9fa');
    root.style.setProperty('--text-color', '#333333');
    root.style.setProperty('--text-secondary', '#6c757d');
    root.style.setProperty('--accent-color', '#2c3e50');
    root.style.setProperty('--accent-light', '#eef2f7');
    root.style.setProperty('--border-color', '#dee2e6');
    root.style.setProperty('--link-color', '#0d6efd');
    root.style.setProperty('--link-hover', '#0a58ca');
    root.style.setProperty('--success-color', '#198754');
    root.style.setProperty('--warning-color', '#ffc107');
    root.style.setProperty('--error-color', '#dc3545');
    root.style.setProperty('--shadow-sm', '0 .125rem .25rem rgba(0,0,0,.075)');
    root.style.setProperty('--shadow-md', '0 .5rem 1rem rgba(0,0,0,.15)');
  } else {
    // Palette scura moderna e sofisticata
    root.style.setProperty('--body-bg', '#1a1a1f');
    root.style.setProperty('--card-bg', '#25252b');
    root.style.setProperty('--text-color', '#e4e4e8');
    root.style.setProperty('--text-secondary', '#9ea7b3');
    root.style.setProperty('--accent-color', '#9fafbf');
    root.style.setProperty('--accent-light', '#2a3038');
    root.style.setProperty('--border-color', '#3a3a40');
    root.style.setProperty('--link-color', '#5dabf0');
    root.style.setProperty('--link-hover', '#82c0f5');
    root.style.setProperty('--success-color', '#4caf84');
    root.style.setProperty('--warning-color', '#f5c455');
    root.style.setProperty('--error-color', '#e55a65');
    root.style.setProperty('--shadow-sm', '0 .125rem .25rem rgba(0,0,0,.3)');
    root.style.setProperty('--shadow-md', '0 .5rem 1rem rgba(0,0,0,.5)');
  }
}

/**
 * Rileva se siamo su un device "mobile" (soglia 768px).
 */
function isMobileDevice(): boolean {
  if (typeof window === 'undefined') return false;
  return window.innerWidth < 768;
}

/**
 * Verifica se l'utente preferisce ridurre motion (accessibilità).
 */
function prefersReducedMotion(): boolean {
  if (typeof window === 'undefined') return false;
  return window.matchMedia('(prefers-reduced-motion: reduce)').matches;
}

/**
 * Provider super-performante con supporto per System theme.
 */
export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [theme, setThemeState] = useState<Theme>(getInitialTheme);
  const [resolvedTheme, setResolvedTheme] = useState<'light' | 'dark'>(
    resolveTheme(getInitialTheme())
  );
  const isMounted = useRef(false);

  // Ascolta le modifiche alle preferenze di sistema
  useEffect(() => {
    if (typeof window === 'undefined') return;
    
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    
    const handleChange = () => {
      if (theme === 'system') {
        setResolvedTheme(getSystemThemePreference());
      }
    };

    // Aggiungi event listener
    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', handleChange);
    } else {
      // Fallback per browser meno recenti
      mediaQuery.addListener(handleChange);
    }

    return () => {
      // Rimuovi event listener
      if (mediaQuery.removeEventListener) {
        mediaQuery.removeEventListener('change', handleChange);
      } else {
        // Fallback per browser meno recenti
        mediaQuery.removeListener(handleChange);
      }
    };
  }, [theme]);

  // Aggiorna il tema risolto ogni volta che il tema cambia
  useEffect(() => {
    setResolvedTheme(resolveTheme(theme));
  }, [theme]);

  /**
   * Applica dark/light + palette.
   */
  const applyTheme = useCallback((themeValue: 'light' | 'dark') => {
    const html = document.documentElement;
    html.classList.remove(DARK_CLASS);
    if (themeValue === 'dark') html.classList.add(DARK_CLASS);
    applyColorPalette(themeValue);
  }, []);

  /**
   * Ogni volta che resolvedTheme cambia, aggiorniamo la palette e le classi.
   * Disabilitiamo transizioni:
   * - se su mobile,
   * - se l'utente preferisce ridurre motion,
   * - se è la primissima volta (no transizione al mount).
   */
  useEffect(() => {
    applyTheme(resolvedTheme);

    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    // Se mobile o preferReducedMotion => no transizione
    if (isMobileDevice() || prefersReducedMotion()) return;

    // Altrimenti (desktop + animazioni non ridotte):
    document.documentElement.classList.add('theme-transition');
    const t = setTimeout(() => {
      document.documentElement.classList.remove('theme-transition');
    }, 500); // Aumentato a 500ms per una transizione più fluida
    return () => clearTimeout(t);
  }, [resolvedTheme, applyTheme]);

  /** setTheme => localStorage + stato. */
  const setTheme = useCallback((newTheme: Theme) => {
    setThemeState(newTheme);
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(STORAGE_KEY, newTheme);
    }
  }, []);

  /** toggleTheme => alterna solo tra light/dark. */
  const toggleTheme = useCallback(() => {
    setThemeState((prev) => {
      // Semplicemente alterna tra light e dark, ignorando system
      const next: Theme = prev === 'light' ? 'dark' : 'light';
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(STORAGE_KEY, next);
      }
      return next;
    });
  }, []);

  // isDark => resolvedTheme === 'dark'
  const isDark = resolvedTheme === 'dark';
  // alias => themeColor
  const themeColor: ThemeColor = resolvedTheme;

  const value = useMemo<ThemeContextType>(() => {
    return {
      theme,
      setTheme,
      toggleTheme,
      isDark,
      themeColor,
    };
  }, [theme, setTheme, toggleTheme, isDark, themeColor]);

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
}

// Aggiunta displayName esplicita per evitare errori "missing display name"
ThemeProvider.displayName = 'ThemeProvider';

/** Hook principale. */
export function useTheme(): ThemeContextType {
  const ctx = useContext(ThemeContext);
  if (!ctx) {
    throw new Error('useTheme deve essere usato dentro ThemeProvider');
  }
  return ctx;
}

/** Se nel codice altrove importi `useIsDark`. */
export function useIsDark(): boolean {
  return useTheme().isDark;
}

/** Se nel codice altrove importi `useThemeColor`. */
export function useThemeColor(): ThemeColor {
  return useTheme().themeColor;
}

/** Hook per ottenere l'attuale tema risolto (sempre light o dark, mai system). */
export function useResolvedTheme(): 'light' | 'dark' {
  return useTheme().themeColor;
}