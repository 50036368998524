import React, { memo, useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useIsDark } from '../context/ThemeContext';
import { Facebook, Twitter, Linkedin, Instagram, Mail, MapPin, Phone, Clock } from 'react-feather';
import styled, { keyframes, css, createGlobalStyle } from 'styled-components';

// ===============================================================
// THEMING SYSTEM WITH ENHANCED COLOR THEORY
// ===============================================================

const lightTheme = {
  background: 'linear-gradient(45deg, #f9f6f0, #f0e9df, #ede3d0)',
  backgroundAlt: 'rgba(255, 255, 255, 0.7)',
  text: '#2c2417',
  textSecondary: '#5a4d3a',
  accent: '#d4af37',
  accentLight: '#efd484',
  accentDark: '#997a28',
  glow: 'rgba(212, 175, 55, 0.6)',
  shadow: 'rgba(0, 0, 0, 0.06)',
  border: 'rgba(212, 175, 55, 0.3)',
  outline: 'rgba(212, 175, 55, 0.5)',
  particle: 'rgba(212, 175, 55, 0.5)',
};

const darkTheme = {
  background: 'linear-gradient(45deg, #1a0b0b, #4a2c3d, #8b5a2b)',
  backgroundAlt: 'rgba(26, 11, 11, 0.5)',
  text: '#f5e6cc',
  textSecondary: '#d3c4a8',
  accent: '#d4af37',
  accentLight: '#efd484',
  accentDark: '#997a28',
  glow: 'rgba(212, 175, 55, 0.6)',
  shadow: 'rgba(0, 0, 0, 0.25)',
  border: 'rgba(212, 175, 55, 0.2)',
  outline: 'rgba(212, 175, 55, 0.4)',
  particle: 'rgba(212, 175, 55, 0.5)',
};

// ===============================================================
// TYPESCRIPT TYPE DEFINITIONS
// ===============================================================

interface ThemeProps {
  $isDark?: boolean;
}

interface ParticleProps {
  x: number;
  y: number;
  size: number;
  speedX: number;
  speedY: number;
  opacity: number;
}

interface LinkDefinition {
  to: string;
  label: string;
}

interface LinkGroupDefinition {
  title: string;
  links: LinkDefinition[];
}

// ===============================================================
// OPTIMIZED ANIMATIONS WITH ENHANCED PHYSICS
// ===============================================================

const shimmer = keyframes`
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
`;

const float = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;

const glowPulseDark = keyframes`
  0% { filter: drop-shadow(0 0 5px ${darkTheme.glow}); }
  50% { filter: drop-shadow(0 0 15px ${darkTheme.glow}); }
  100% { filter: drop-shadow(0 0 5px ${darkTheme.glow}); }
`;

const glowPulseLight = keyframes`
  0% { filter: drop-shadow(0 0 5px ${lightTheme.glow}); }
  50% { filter: drop-shadow(0 0 15px ${lightTheme.glow}); }
  100% { filter: drop-shadow(0 0 5px ${lightTheme.glow}); }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

// ===============================================================
// FOCUS VISIBLE STYLING FOR ACCESSIBILITY
// ===============================================================

interface FocusStylesProps {
  $isDark?: boolean;
}

const FocusStyles = createGlobalStyle<FocusStylesProps>`
  .footer-focus-visible:focus-visible {
    outline: 2px solid ${props => props.$isDark ? darkTheme.outline : lightTheme.outline};
    outline-offset: 3px;
    box-shadow: 0 0 0 4px ${props => props.$isDark ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.3)'};
    border-radius: 2px;
    transition: outline-offset 0.2s ease;
  }
`;

// ===============================================================
// STYLED COMPONENTS WITH REFINED AESTHETICS
// ===============================================================

const FooterWrapper = styled(motion.footer)<ThemeProps>`
  position: relative;
  padding: 1.8rem 3rem 1.2rem;
  background: ${props => props.$isDark ? darkTheme.background : lightTheme.background};
  color: ${props => props.$isDark ? darkTheme.text : lightTheme.text};
  overflow: hidden;
  border-top: 2px solid ${props => props.$isDark ? darkTheme.accent : lightTheme.accent};
  transition: all 0.5s cubic-bezier(0.22, 1, 0.36, 1);
  box-shadow: 0 -10px 30px ${props => props.$isDark ? darkTheme.shadow : lightTheme.shadow};
  
  @media (max-width: 768px) {
    padding: 1.5rem 1.5rem 1rem;
  }
`;

const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
`;

const FluidShape = styled(motion.svg)`
  position: absolute;
  top: -30px;
  left: -50px;
  width: 300px;
  height: 300px;
  opacity: 0.25;
  z-index: 0;
  pointer-events: none;
  
  @media (max-width: 768px) {
    width: 200px;
    height: 200px;
    top: -20px;
    left: -30px;
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0;
  }
`;

// BrandSection styled to match exactly what's in the screenshots
const BrandSection = styled(motion.div)`
  flex: 1;
  min-width: 280px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  @media (max-width: 480px) {
    min-width: 100%;
    align-items: center;
  }
`;

// Circle element that exactly matches the screenshots
const CircleElement = styled.div<ThemeProps>`
  width: 160px;
  height: 160px;
  border-radius: 50%;
  position: relative;
  background: ${props => props.$isDark 
    ? 'radial-gradient(circle, rgba(139, 90, 43, 0.3) 0%, rgba(26, 11, 11, 0.8) 100%)' 
    : 'radial-gradient(circle, rgba(212, 175, 55, 0.3) 0%, rgba(153, 122, 40, 0.2) 100%)'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  border: 1px solid ${props => props.$isDark ? 'rgba(212, 175, 55, 0.2)' : 'rgba(212, 175, 55, 0.3)'};
  margin-bottom: 20px;
`;

// Company logo initials styled exactly like in the screenshots
const CompanyInitials = styled.div<ThemeProps>`
  font-family: 'Didot', serif;
  font-size: 60px;
  font-weight: bold;
  color: ${props => props.$isDark ? 'rgba(212, 175, 55, 0.8)' : 'rgba(212, 175, 55, 0.8)'};
  margin-bottom: 5px;
  text-shadow: 0 0 10px rgba(212, 175, 55, 0.4);
  line-height: 1;
`;

// Tagline text styled exactly like in the screenshots
const CompanyTagline = styled.p<ThemeProps>`
  font-family: 'Didot', serif;
  font-size: 14px;
  font-style: italic;
  color: ${props => props.$isDark ? 'rgba(212, 175, 55, 0.8)' : 'rgba(212, 175, 55, 0.8)'};
  text-align: center;
  line-height: 1.3;
  max-width: 150px;
  margin: 10px 0;
`;

// Social icons styled exactly like in the screenshots
const FooterSocialIcons = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
  width: 100%;
`;

// Each social icon styled exactly like in the screenshots
const FooterSocialIcon = styled.a<ThemeProps>`
  color: ${props => props.$isDark ? 'rgba(212, 175, 55, 0.8)' : 'rgba(212, 175, 55, 0.8)'};
  font-size: 18px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: ${props => props.$isDark ? '#fff' : '#fff'};
    transform: translateY(-3px);
  }
`;

// Contact section
const ContactSection = styled(motion.div)<ThemeProps>`
  flex: 1.3;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  border-left: 1px solid ${props => props.$isDark ? 'rgba(212, 175, 55, 0.15)' : 'rgba(212, 175, 55, 0.2)'};
  
  @media (max-width: 1024px) {
    flex: 1;
    padding-left: 0.5rem;
  }
  
  @media (max-width: 768px) {
    width: 100%;
    border-left: none;
    border-top: 1px solid ${props => props.$isDark ? 'rgba(212, 175, 55, 0.15)' : 'rgba(212, 175, 55, 0.2)'};
    padding-left: 0;
    padding-top: 1rem;
    margin-top: 1rem;
  }
`;

// Links section
const LinksSection = styled.div<ThemeProps>`
  flex: 2;
  display: flex;
  justify-content: space-around;
  gap: 1.5rem;
  padding-left: 1rem;
  border-left: 1px solid ${props => props.$isDark ? 'rgba(212, 175, 55, 0.15)' : 'rgba(212, 175, 55, 0.2)'};
  
  @media (max-width: 1024px) {
    flex: 1.5;
    padding-left: 0.5rem;
  }
  
  @media (max-width: 768px) {
    width: 100%;
    border-left: none;
    border-top: 1px solid ${props => props.$isDark ? 'rgba(212, 175, 55, 0.15)' : 'rgba(212, 175, 55, 0.2)'};
    padding-left: 0;
    padding-top: 1rem;
    margin-top: 1rem;
    gap: 1rem;
  }
`;

// Fixed: Adding proper definition for LinkGroup with ThemeProps
const LinkGroup = styled(motion.div)`
  min-width: 200px;
  
  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

// Contact section and links styling
const LinkTitle = styled.h3<ThemeProps>`
  font-family: 'Vogue', serif;
  font-size: 1.4rem;
  color: ${props => props.$isDark ? darkTheme.accent : lightTheme.accent};
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0 0 8px ${props => props.$isDark ? darkTheme.glow : lightTheme.glow};
  margin-bottom: 0.8rem;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 30px;
    height: 2px;
    background: ${props => props.$isDark ? darkTheme.accent : lightTheme.accent};
  }
  
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const LinksList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

const LinkItem = styled(motion.a)<ThemeProps>`
  font-family: 'Didot', serif;
  font-size: 0.95rem;
  color: ${props => props.$isDark ? darkTheme.text : lightTheme.text};
  position: relative;
  transition: all 0.3s ease;
  padding: 0.2rem 0;
  display: inline-block;
  
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background: ${props => props.$isDark ? darkTheme.accent : lightTheme.accent};
    transition: width 0.3s cubic-bezier(0.25, 1, 0.5, 1);
  }
  
  &:hover, &:focus {
    color: ${props => props.$isDark ? darkTheme.accent : lightTheme.accent};
    text-decoration: none;
  }
  
  &:hover:after, &:focus:after {
    width: 100%;
  }
  
  &:focus-visible {
    outline: 2px solid ${props => props.$isDark ? darkTheme.outline : lightTheme.outline};
    outline-offset: 3px;
  }
`;

const ContactItem = styled.div<ThemeProps>`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  margin-bottom: 0.4rem;
  font-size: 0.9rem;
  color: ${props => props.$isDark ? darkTheme.textSecondary : lightTheme.textSecondary};
  transition: all 0.2s ease;
  
  svg {
    color: ${props => props.$isDark ? darkTheme.accent : lightTheme.accent};
    min-width: 16px;
    width: 16px;
    height: 16px;
  }
  
  &:hover {
    transform: translateX(3px);
    color: ${props => props.$isDark ? darkTheme.text : lightTheme.text};
  }
`;

const FooterBottom = styled.div<ThemeProps>`
  position: relative;
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid ${props => props.$isDark ? darkTheme.border : lightTheme.border};
`;

const Copyright = styled(motion.p)<ThemeProps>`
  text-align: center;
  font-family: 'Didot', serif;
  font-size: 1rem;
  color: ${props => props.$isDark ? darkTheme.textSecondary : lightTheme.textSecondary};
  text-shadow: 0 0 5px ${props => props.$isDark ? darkTheme.glow : lightTheme.glow};
  
  a {
    color: ${props => props.$isDark ? darkTheme.accent : lightTheme.accent};
    text-decoration: none;
    transition: color 0.3s ease;
    
    &:hover, &:focus {
      color: ${props => props.$isDark ? darkTheme.accentLight : lightTheme.accentDark};
      text-decoration: underline;
    }
    
    &:focus-visible {
      outline: 2px solid ${props => props.$isDark ? darkTheme.outline : lightTheme.outline};
      outline-offset: 3px;
    }
  }
`;

// ===============================================================
// PARTICLE SYSTEM WITH ENHANCED PHYSICS
// ===============================================================

class Particle implements ParticleProps {
  x: number;
  y: number;
  size: number;
  speedX: number;
  speedY: number;
  opacity: number;
  angle: number;
  angularSpeed: number;
  
  constructor(canvas: HTMLCanvasElement) {
    this.x = Math.random() * canvas.width;
    this.y = Math.random() * canvas.height;
    this.size = Math.random() * 2.5 + 0.5;
    this.speedX = (Math.random() - 0.5) * 0.3;
    this.speedY = (Math.random() - 0.5) * 0.3;
    this.opacity = Math.random() * 0.5 + 0.2;
    this.angle = Math.random() * Math.PI * 2;
    this.angularSpeed = (Math.random() - 0.5) * 0.02;
  }

  update(ctx: CanvasRenderingContext2D, mouse: { x: number; y: number }, isDark: boolean, deltaTime: number) {
    // Apply time-based movement for consistent speed across different devices
    this.x += this.speedX * deltaTime;
    this.y += this.speedY * deltaTime;
    this.angle += this.angularSpeed * deltaTime;
    
    // Boundary wrapping
    if (this.x > ctx.canvas.width) this.x = 0;
    if (this.x < 0) this.x = ctx.canvas.width;
    if (this.y > ctx.canvas.height) this.y = 0;
    if (this.y < 0) this.y = ctx.canvas.height;

    // Mouse interaction with smoother falloff
    const dx = mouse.x - this.x;
    const dy = mouse.y - this.y;
    const dist = Math.sqrt(dx * dx + dy * dy);
    const maxDist = 100;
    
    if (dist < maxDist) {
      // Cubic falloff for smoother transition
      const falloff = Math.pow(1 - dist / maxDist, 3) * 0.3;
      
      // Draw connection with adaptive opacity
      ctx.beginPath();
      ctx.moveTo(this.x, this.y);
      ctx.lineTo(mouse.x, mouse.y);
      ctx.strokeStyle = `rgba(212, 175, 55, ${falloff})`;
      ctx.lineWidth = 0.5;
      ctx.stroke();
      
      // Add subtle repulsion from mouse
      const forceFactor = 0.02 * falloff;
      const angle = Math.atan2(dy, dx);
      this.speedX -= Math.cos(angle) * forceFactor;
      this.speedY -= Math.sin(angle) * forceFactor;
    }
    
    // Apply subtle damping for more natural deceleration
    this.speedX *= 0.99;
    this.speedY *= 0.99;
    
    // Speed limits to prevent particles from moving too fast
    const maxSpeed = 0.5;
    const currentSpeed = Math.sqrt(this.speedX * this.speedX + this.speedY * this.speedY);
    if (currentSpeed > maxSpeed) {
      this.speedX = (this.speedX / currentSpeed) * maxSpeed;
      this.speedY = (this.speedY / currentSpeed) * maxSpeed;
    }

    // Draw the particle
    const color = isDark ? darkTheme.particle : lightTheme.particle;
    ctx.globalAlpha = this.opacity;
    
    // Use rotation for a more dynamic look
    ctx.save();
    ctx.translate(this.x, this.y);
    ctx.rotate(this.angle);
    
    // Draw as small rounded rectangle instead of circle for variety
    const halfSize = this.size / 2;
    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.roundRect(-halfSize, -halfSize, this.size, this.size, this.size / 3);
    ctx.fill();
    
    ctx.restore();
    ctx.globalAlpha = 1;
  }
  
  // Connect nearby particles for a network effect
  connect(particles: Particle[], ctx: CanvasRenderingContext2D, isDark: boolean) {
    const connectionRadius = 70; // Maximum distance for connections
    const color = isDark ? darkTheme.particle : lightTheme.particle;
    
    particles.forEach(particle => {
      const dx = this.x - particle.x;
      const dy = this.y - particle.y;
      const dist = Math.sqrt(dx * dx + dy * dy);
      
      if (this !== particle && dist < connectionRadius) {
        // Quadratic falloff for more natural looking connections
        const opacity = (1 - (dist / connectionRadius) ** 2) * 0.15;
        
        ctx.beginPath();
        ctx.strokeStyle = `rgba(212, 175, 55, ${opacity})`;
        ctx.lineWidth = 0.3;
        ctx.moveTo(this.x, this.y);
        ctx.lineTo(particle.x, particle.y);
        ctx.stroke();
      }
    });
  }
}

// ===============================================================
// MAIN COMPONENT WITH PERFORMANCE OPTIMIZATIONS
// ===============================================================

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const isDark = useIsDark();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const animationRef = useRef<number>(0);
  const lastTimeRef = useRef<number>(0);
  const particlesRef = useRef<Particle[]>([]);
  
  // Layout orizzontale - Dati organizzati per sezioni
  const footerData = useMemo(() => {
    return {
      contact: [
        { icon: MapPin, text: 'Calle Principal 123, Santa Cruz de Tenerife' },
        { icon: Phone, text: '+34 922 123 456' },
        { icon: Mail, text: 'info@contabletenerife.com' },
        { icon: Clock, text: 'Lun-Vie: 9:00 - 18:00' }
      ],
      social: [Facebook, Twitter, Linkedin, Instagram],
      links: [
        { 
          title: t('footersito.companySectionTitle') || 'COMPANY', 
          links: [
            { to: '/about', label: t('footersito.aboutUs') || 'About Us' }, 
            { to: '/services', label: t('footersito.services') || 'Our Services' },
            { to: '/team', label: 'Nuestro Equipo' }
          ] 
        },
        { 
          title: t('footersito.legalSectionTitle') || 'LEGAL', 
          links: [
            { to: '/privacy', label: t('footersito.privacy') || 'Privacy Policy' },
            { to: '/terms', label: 'Términos y Condiciones' },
            { to: '/cookies', label: 'Política de Cookies' }
          ] 
        }
      ]
    };
  }, [t]);

  const handleMouseMove = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setMousePos({ 
      x: e.clientX - rect.left, 
      y: e.clientY - rect.top 
    });
  }, []);
  
  const handleMouseLeave = useCallback(() => {
    setMousePos(prev => ({ 
      x: prev.x,
      y: -100
    }));
  }, []);

  // Performance-optimized animation loop with consistent timing
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Initialize canvas with device pixel ratio for sharp rendering
    const setupCanvas = () => {
      const devicePixelRatio = window.devicePixelRatio || 1;
      canvas.width = canvas.offsetWidth * devicePixelRatio;
      canvas.height = 150 * devicePixelRatio;
      ctx.scale(devicePixelRatio, devicePixelRatio);
      canvas.style.width = `${canvas.offsetWidth}px`;
      canvas.style.height = `150px`;
    };
    
    setupCanvas();
    
    // Create particles
    particlesRef.current = [];
    const particleCount = window.innerWidth < 768 ? 25 : 40;
    
    for (let i = 0; i < particleCount; i++) {
      particlesRef.current.push(new Particle(canvas));
    }
    
    // Animation loop with deltaTime for consistent speed
    const animate = (timestamp: number) => {
      if (!lastTimeRef.current) lastTimeRef.current = timestamp;
      const deltaTime = (timestamp - lastTimeRef.current) / 16; // Normalize to ~60fps
      lastTimeRef.current = timestamp;
      
      ctx.clearRect(0, 0, canvas.offsetWidth, canvas.offsetHeight);
      
      // Update and draw particles
      particlesRef.current.forEach(particle => {
        particle.update(ctx, mousePos, isDark, deltaTime);
      });
      
      // Draw connections between nearby particles
      particlesRef.current.forEach(particle => {
        particle.connect(particlesRef.current, ctx, isDark);
      });
      
      animationRef.current = requestAnimationFrame(animate);
    };
    
    animationRef.current = requestAnimationFrame(animate);
    
    // Resize handler
    const handleResize = () => {
      setupCanvas();
    };
    
    window.addEventListener('resize', handleResize);
    
    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(animationRef.current);
    };
  }, [mousePos, isDark]);

  return (
    <>
      <FocusStyles $isDark={isDark} />
      <FooterWrapper
        $isDark={isDark}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: [0.25, 0.1, 0.25, 1] }}
      >
        <Canvas ref={canvasRef} aria-hidden="true" />
        
        <FluidShape
          viewBox="0 0 200 200"
          initial={{ rotate: 0, opacity: 0 }}
          animate={{ rotate: 360, opacity: 0.25 }}
          transition={{ 
            rotate: { 
              duration: 60, 
              repeat: Infinity, 
              ease: 'linear' 
            },
            opacity: { 
              duration: 1, 
              ease: 'easeOut' 
            }
          }}
          aria-hidden="true"
        >
          <path 
            fill={isDark ? darkTheme.accent : lightTheme.accent} 
            d="M48.8,-64.2C59.4,-51.2,61.9,-32.9,66.4,-14.4C70.9,4.1,77.3,22.7,72.3,36.8C67.2,50.9,50.7,60.5,33.4,66.8C16.1,73.1,-2.1,76.2,-17.9,71.2C-33.7,66.3,-47.2,53.3,-59.5,38.1C-71.7,22.8,-82.8,5.3,-81.4,-11.3C-80,-28,-66.1,-43.6,-50.4,-57C-34.7,-70.3,-17.3,-81.3,0.6,-82C18.6,-82.7,37.1,-72.9,48.8,-59.7Z" 
            transform="translate(100 100)" 
          />
        </FluidShape>
        
        <Content>
          {/* Brand section with circle element exactly as in the screenshots */}
          <BrandSection
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.1 }}
          >
            <CircleElement $isDark={isDark}>
              <CompanyInitials $isDark={isDark}>CT</CompanyInitials>
              <CompanyTagline $isDark={isDark}>
                Professional accounting and tax consulting services in Tenerife.
              </CompanyTagline>
              <FooterSocialIcons>
                {[Facebook, Twitter, Linkedin, Instagram].map((Icon, i) => (
                  <FooterSocialIcon
                    key={i}
                    href="#"
                    $isDark={isDark}
                    className="footer-focus-visible"
                    aria-label={
                      i === 0 ? 'Facebook' :
                      i === 1 ? 'Twitter' :
                      i === 2 ? 'LinkedIn' : 'Instagram'
                    }
                  >
                    <Icon size={18} />
                  </FooterSocialIcon>
                ))}
              </FooterSocialIcons>
            </CircleElement>
          </BrandSection>

          {/* Contact section */}
          <ContactSection
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            $isDark={isDark}
          >
            <LinkTitle $isDark={isDark}>Contacto</LinkTitle>
            {footerData.contact.map((item, i) => (
              <ContactItem key={i} $isDark={isDark}>
                <item.icon size={16} />
                <span>{item.text}</span>
              </ContactItem>
            ))}
          </ContactSection>
          
          <LinksSection $isDark={isDark}>
            {footerData.links.map((group, i) => (
              <LinkGroup
                key={i}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.2 + i * 0.1 }}
              >
                <LinkTitle $isDark={isDark}>{group.title}</LinkTitle>
                <LinksList>
                  {group.links.map((link, j) => (
                    <LinkItem
                      key={j}
                      as={Link}
                      to={link.to}
                      $isDark={isDark}
                      whileHover={{ x: 5 }}
                      transition={{ type: 'spring', stiffness: 300 }}
                      className="footer-focus-visible"
                    >
                      {link.label}
                    </LinkItem>
                  ))}
                </LinksList>
              </LinkGroup>
            ))}
          </LinksSection>
        </Content>
        
        <FooterBottom $isDark={isDark}>
          <Copyright
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.4 }}
            $isDark={isDark}
          >
            © {new Date().getFullYear()} Contable Tenerife. {t('footersito.allRights') || 'Todos los derechos reservados'} | 
            <span aria-hidden="true"> </span>
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.6 }}
            >
              Made with Haute Couture ❤️
            </motion.span>
          </Copyright>
        </FooterBottom>
      </FooterWrapper>
    </>
  );
};

export default memo(Footer);