import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  motion,
  AnimatePresence,
  useMotionValue,
  useTransform,
  useSpring,
  MotionValue,
  Variants,
  useCycle,
  useAnimation,
  useMotionTemplate,
  AnimationControls,
  Transition,
  TargetAndTransition,
} from 'framer-motion';
import { useTheme } from '../context/ThemeContext';

/**
 * Interfaccia sofisticata per la singola lingua con proprietà estese.
 */
interface Language {
  code: string;                       // Codice ISO della lingua
  name: string;                       // Nome nella lingua originale
  localName?: string;                 // Nome nella lingua corrente (opzionale)
  flag: string;                       // Emoji della bandiera
  gradient: string;                   // Gradiente bandiera personalizzato
  hoverGradient?: string;             // Gradiente alternativo per hover (opzionale)
  textColor?: string;                 // Colore testo ottimizzato per il gradiente (opzionale)
  accentColor?: string;               // Colore di accento/evidenziazione (opzionale)
  secondaryColor?: string;            // Colore secondario per effetti (opzionale)
  fontWeight?: string | number;       // Peso del font specifico (opzionale)
  fontFamily?: string;                // Famiglia di font specifica (opzionale)
  symbolPosition?: 'left' | 'right';  // Posizione bandiera (opzionale)
  phoneticName?: string;              // Nome fonetico (opzionale)
  luxuryVariant?: 'gold' | 'silver' | 'platinum' | 'diamond'; // Variante di lusso (opzionale)
}

/**
 * Interfaccia estesa per effetti visivi avanzati
 */
interface VisualEffects {
  glassEffect: boolean;            // Effetto vetro/morphismo
  reflections: boolean;            // Riflessi ambientali
  neomorphism: boolean;            // Effetto neumorfismo
  particleEffect: boolean;         // Effetto particellare
  borderGlow: boolean;             // Effetto bagliore ai bordi
  textGlow: boolean;               // Effetto bagliore sul testo
  metallic: boolean;               // Effetto metallico
  paperCut: boolean;               // Effetto carta tagliata/stratificazione
  flagWave: boolean;               // Animazione ondeggiamento bandiera
  luxuryMetallic: boolean;         // Effetti metallici di lusso
  textReveal: boolean;             // Animazioni di rivelazione testo
  backgroundNoise: boolean;        // Rumore di fondo sottile
  gradientShift: boolean;          // Cambio gradiente animato
  shimmerEffect: boolean;          // Effetto brillantinato
  textShadow: boolean;             // Ombra testo personalizzata
  depthMapping: boolean;           // Mappatura profondità 3D
  presenceAnimation: boolean;      // Animazione di presenza/entrata
  iconAnimation: boolean;          // Animazione delle icone
  materialDesign: 'classic' | 'neo' | 'glass' | 'neumorphic' | 'material' | 'luxury' | 'elegant' | 'minimalist' | 'brutalist' | 'organic';
}

/**
 * Proprietà del componente LanguageSwitcher con opzioni premium.
 */
interface LanguageSwitcherProps {
  onLanguageChange?: (language: string) => void;  // Callback con parametro lingua
  className?: string;                             // Classi CSS aggiuntive
  buttonSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';  // Dimensione pulsante (opzionale)
  dropdownPosition?: 'left' | 'right' | 'center'; // Posizione dropdown (opzionale)
  showFlags?: boolean;                            // Mostra bandiere (default: true)
  showNames?: boolean;                            // Mostra nomi (default: true)
  animationLevel?: 'subtle' | 'moderate' | 'rich' | 'premium' | 'luxury'; // Livello animazioni
  borderRadius?: string;                          // Raggio bordo personalizzato (opzionale)
  dropdownWidth?: string;                         // Larghezza dropdown personalizzata (opzionale)
  dropdownMaxHeight?: string;                     // Altezza max dropdown personalizzata (opzionale)
  elevated?: boolean;                             // Effetto elevazione (default: true)
  visualEffects?: Partial<VisualEffects>;         // Effetti visivi avanzati
  layoutMode?: 'default' | 'compact' | 'expanded' | 'centered'; // Modalità layout
  displayMode?: 'standard' | 'minimal' | 'elegant' | 'decorative' | 'premium'; // Modalità visualizzazione
  customGradient?: string;                        // Gradiente personalizzato
  hoverType?: 'scale' | 'glow' | 'shift' | 'ripple' | 'morph'; // Tipo di hover
  themingLevel?: 'inherit' | 'custom' | 'contrast'; // Livello di theming
  transitionSpeed?: 'instant' | 'fast' | 'normal' | 'slow' | 'elegant'; // Velocità transizioni
  interactionStyle?: 'standard' | 'subtle' | 'emphatic' | 'theatrical'; // Stile interazioni
  focusIndicatorStyle?: 'standard' | 'minimal' | 'glow' | 'pulse'; // Stile indicatore focus
  presenceMode?: 'fade' | 'slide' | 'scale' | 'elaborate'; // Modalità di presenza
  dropdownStagger?: number;                       // Tempo di stagger dropdown
  metaTheme?: 'finance' | 'corporate' | 'luxury' | 'technology' | 'creative'; // Meta-tema
  iconSet?: 'emoji' | 'flags' | 'minimal' | 'elegant' | 'abstract'; // Set di icone
  semanticLevel?: 'high' | 'standard' | 'minimal'; // Livello semantica ARIA
}

/**
 * Definizione di stili globali di effetti in base al tema per un aspetto coerente.
 */
interface PremiumVisualTheme {
  reflectionOpacity: number;           // Opacità per riflessi
  glassBlur: string;                   // Valore blur per effetti vetro
  shimmerSpeed: number;                // Velocità effetti brillantinati
  particleDensity: number;             // Densità particelle
  hoverAmplification: number;          // Amplificazione effetti hover
  activeAmplification: number;         // Amplificazione effetti attivi
  textGlowRadius: string;              // Raggio bagliore testo
  outlineWidth: string;                // Spessore contorni
  shadowIntensity: number;             // Intensità ombre
  colorSaturation: number;             // Saturazione colore
  animationTiming: {                   // Timing animazioni
    fast: number;                      
    normal: number;
    slow: number;
    elegant: number;
  };
  depthFactor: number;                 // Fattore di profondità 3D
  noiseIntensity: number;              // Intensità pattern rumore
  backdropInversion: number;           // Inversione sfondo
  layeringDepth: number;               // Livelli profondità
  blendingFactor: string;              // Modo di fusione
  accentStrength: number;              // Intensità accenti
  shadowSpread: string;                // Diffusione ombre
  chromaticDispersion: string;         // Dispersione cromatica
}

/**
 * Catalogo lingue esteso con gradienti premium, ottimizzato per design professionale
 * con stili lussuosi e sofisticati per un sito di commercialista di alto livello.
 */
const languages: Language[] = [
  {
    code: 'en',
    name: 'English',
    localName: 'English',
    flag: '🇬🇧',
    gradient: 'linear-gradient(135deg, #012169 0%, #012169 20%, #FFFFFF 35%, #C8102E 50%, #FFFFFF 65%, #012169 80%, #012169 100%)',
    hoverGradient: 'linear-gradient(135deg, #001e5a 0%, #001e5a 20%, #f0f0f0 35%, #b70022 50%, #f0f0f0 65%, #001e5a 80%, #001e5a 100%)',
    textColor: '#FFFFFF',
    accentColor: '#C8102E',
    secondaryColor: '#012169',
    fontWeight: 600,
    symbolPosition: 'left',
    luxuryVariant: 'silver',
  },
  {
    code: 'es',
    name: 'Español',
    localName: 'Español',
    flag: '🇪🇸',
    gradient: 'linear-gradient(135deg, #AA151B 0%, #F1BF00 30%, #F1BF00 70%, #AA151B 100%)',
    hoverGradient: 'linear-gradient(135deg, #9a1218 0%, #e1b000 30%, #e1b000 70%, #9a1218 100%)',
    textColor: '#000000',
    accentColor: '#AA151B',
    secondaryColor: '#F1BF00',
    fontWeight: 600,
    symbolPosition: 'left',
    luxuryVariant: 'gold',
  },
  {
    code: 'fr',
    name: 'Français',
    localName: 'Français',
    flag: '🇫🇷',
    gradient: 'linear-gradient(135deg, #0055A4 0%, #0055A4 30%, #FFFFFF 30%, #FFFFFF 70%, #EF4135 70%, #EF4135 100%)',
    hoverGradient: 'linear-gradient(135deg, #004a8f 0%, #004a8f 30%, #f0f0f0 30%, #f0f0f0 70%, #da3a30 70%, #da3a30 100%)',
    textColor: '#FFFFFF',
    accentColor: '#EF4135',
    secondaryColor: '#0055A4',
    fontWeight: 600,
    symbolPosition: 'left',
    luxuryVariant: 'platinum',
  },
  {
    code: 'de',
    name: 'Deutsch',
    localName: 'Deutsch',
    flag: '🇩🇪',
    gradient: 'linear-gradient(135deg, #000000 0%, #000000 33%, #DD0000 33%, #DD0000 66%, #FFCE00 66%, #FFCE00 100%)',
    hoverGradient: 'linear-gradient(135deg, #000000 0%, #000000 33%, #cc0000 33%, #cc0000 66%, #ebbd00 66%, #ebbd00 100%)',
    textColor: '#FFFFFF',
    accentColor: '#FFCE00',
    secondaryColor: '#DD0000',
    fontWeight: 600,
    symbolPosition: 'left',
    luxuryVariant: 'gold',
  },
  {
    code: 'it',
    name: 'Italiano',
    localName: 'Italiano',
    flag: '🇮🇹',
    gradient: 'linear-gradient(135deg, #009246 0%, #009246 33%, #FFFFFF 33%, #FFFFFF 66%, #CE2B37 66%, #CE2B37 100%)',
    hoverGradient: 'linear-gradient(135deg, #00833f 0%, #00833f 33%, #f0f0f0 33%, #f0f0f0 66%, #ba2732 66%, #ba2732 100%)',
    textColor: '#FFFFFF',
    accentColor: '#CE2B37',
    secondaryColor: '#009246',
    fontWeight: 600,
    symbolPosition: 'left',
    luxuryVariant: 'diamond',
  },
  {
    code: 'ru',
    name: 'Русский',
    localName: 'Русский',
    flag: '🇷🇺',
    gradient: 'linear-gradient(135deg, #FFFFFF 0%, #FFFFFF 33%, #0039A6 33%, #0039A6 66%, #D52B1E 66%, #D52B1E 100%)',
    hoverGradient: 'linear-gradient(135deg, #f0f0f0 0%, #f0f0f0 33%, #003391 33%, #003391 66%, #c1271b 66%, #c1271b 100%)',
    textColor: '#FFFFFF',
    accentColor: '#D52B1E',
    secondaryColor: '#0039A6',
    fontWeight: 600,
    symbolPosition: 'left',
    luxuryVariant: 'platinum',
  },
];

/**
 * Definizione del tema visuale premium con valori calibrati per un aspetto professionale e sofisticato.
 */
const premiumVisualTheme: PremiumVisualTheme = {
  reflectionOpacity: 0.45,
  glassBlur: '10px',
  shimmerSpeed: 2.5,
  particleDensity: 8,
  hoverAmplification: 1.15,
  activeAmplification: 1.25,
  textGlowRadius: '5px',
  outlineWidth: '1.5px',
  shadowIntensity: 0.75,
  colorSaturation: 1.05,
  animationTiming: {
    fast: 0.2,
    normal: 0.4,
    slow: 0.7,
    elegant: 1.2,
  },
  depthFactor: 1.8,
  noiseIntensity: 0.03,
  backdropInversion: 0.05,
  layeringDepth: 5,
  blendingFactor: 'overlay',
  accentStrength: 1.2,
  shadowSpread: '8px',
  chromaticDispersion: '0.5px',
};

/**
 * Funzione per mappare i profili di animazione in base al livello selezionato
 */
const mapAnimationProfile = (level: LanguageSwitcherProps['animationLevel']) => {
  switch (level) {
    case 'subtle':
      return {
        intensity: 0.6,
        rotation: 2.5,
        scale: 1.02,
        speed: 0.85,
        complexity: 0.3,
      };
    case 'moderate':
      return {
        intensity: 0.8,
        rotation: 5,
        scale: 1.035,
        speed: 1,
        complexity: 0.6,
      };
    case 'rich':
      return {
        intensity: 1,
        rotation: 7.5,
        scale: 1.05,
        speed: 1.15,
        complexity: 0.8,
      };
    case 'premium':
      return {
        intensity: 1.2,
        rotation: 8.5,
        scale: 1.065,
        speed: 1.3,
        complexity: 0.9,
      };
    case 'luxury':
      return {
        intensity: 1.5,
        rotation: 10,
        scale: 1.08,
        speed: 1.5,
        complexity: 1,
      };
    default:
      return {
        intensity: 0.8,
        rotation: 5,
        scale: 1.035,
        speed: 1,
        complexity: 0.6,
      };
  }
};

/**
 * Utilità per generare CSS per effetti di lusso
 */
const generateLuxuryStyles = (variant: Language['luxuryVariant'], themeMode: string) => {
  // Assicuriamo che il tema sia solo 'light' o 'dark'
  const safeTheme = themeMode === 'dark' ? 'dark' : 'light';
  const baseGlowIntensity = safeTheme === 'light' ? 0.4 : 0.6;
  const baseShadowIntensity = safeTheme === 'light' ? 0.5 : 0.7;
  
  switch (variant) {
    case 'gold':
      return {
        borderGradient: 'linear-gradient(135deg, #FDE08D 0%, #DF9F28 50%, #FDE08D 100%)',
        glow: `0 0 12px rgba(223, 159, 40, ${baseGlowIntensity})`,
        textShadow: `0 1px 2px rgba(0, 0, 0, ${baseShadowIntensity})`,
        metalFilter: 'saturate(1.4) brightness(1.1)',
        backgroundBlend: 'overlay',
        accentColor: '#DF9F28',
      };
    case 'silver':
      return {
        borderGradient: 'linear-gradient(135deg, #F7F7F7 0%, #C0C0C0 50%, #F7F7F7 100%)',
        glow: `0 0 12px rgba(192, 192, 192, ${baseGlowIntensity})`,
        textShadow: `0 1px 2px rgba(0, 0, 0, ${baseShadowIntensity})`,
        metalFilter: 'saturate(0.3) brightness(1.15)',
        backgroundBlend: 'hard-light',
        accentColor: '#C0C0C0',
      };
    case 'platinum':
      return {
        borderGradient: 'linear-gradient(135deg, #E5E4E2 0%, #A9A8A7 50%, #E5E4E2 100%)',
        glow: `0 0 12px rgba(169, 168, 167, ${baseGlowIntensity})`,
        textShadow: `0 1px 2px rgba(0, 0, 0, ${baseShadowIntensity})`,
        metalFilter: 'saturate(0.2) brightness(1.2)',
        backgroundBlend: 'soft-light',
        accentColor: '#A9A8A7',
      };
    case 'diamond':
      return {
        borderGradient: 'linear-gradient(135deg, #B9F2FF 0%, #87CEEB 50%, #B9F2FF 100%)',
        glow: `0 0 15px rgba(135, 206, 235, ${baseGlowIntensity + 0.2})`,
        textShadow: `0 1px 2px rgba(0, 0, 0, ${baseShadowIntensity})`,
        metalFilter: 'saturate(0.5) brightness(1.3)',
        backgroundBlend: 'screen',
        accentColor: '#87CEEB',
      };
    default:
      return {
        borderGradient: 'linear-gradient(135deg, #E5E4E2 0%, #A9A8A7 50%, #E5E4E2 100%)',
        glow: `0 0 12px rgba(169, 168, 167, ${baseGlowIntensity})`,
        textShadow: `0 1px 2px rgba(0, 0, 0, ${baseShadowIntensity})`,
        metalFilter: 'saturate(0.2) brightness(1.2)',
        backgroundBlend: 'soft-light',
        accentColor: '#A9A8A7',
      };
  }
};

// Definizione diretta dei gradienti per garantire disponibilità immediata
const PREDEFINED_GRADIENTS = {
  en: 'linear-gradient(135deg, #012169 0%, #012169 20%, #FFFFFF 35%, #C8102E 50%, #FFFFFF 65%, #012169 80%, #012169 100%)',
  es: 'linear-gradient(135deg, #AA151B 0%, #F1BF00 30%, #F1BF00 70%, #AA151B 100%)',
  fr: 'linear-gradient(135deg, #0055A4 0%, #0055A4 30%, #FFFFFF 30%, #FFFFFF 70%, #EF4135 70%, #EF4135 100%)',
  de: 'linear-gradient(135deg, #000000 0%, #000000 33%, #DD0000 33%, #DD0000 66%, #FFCE00 66%, #FFCE00 100%)',
  it: 'linear-gradient(135deg, #009246 0%, #009246 33%, #FFFFFF 33%, #FFFFFF 66%, #CE2B37 66%, #CE2B37 100%)',
  ru: 'linear-gradient(135deg, #FFFFFF 0%, #FFFFFF 33%, #0039A6 33%, #0039A6 66%, #D52B1E 66%, #D52B1E 100%)',
};

// Predefinizione delle bandiere
const FLAG_EMOJIS = {
  en: '🇬🇧',
  es: '🇪🇸',
  fr: '🇫🇷',
  de: '🇩🇪',
  it: '🇮🇹',
  ru: '🇷🇺',
};
const LuxuryLanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  onLanguageChange,
  className = '',
  buttonSize = 'md',
  dropdownPosition = 'right',
  showFlags = true,
  showNames = true,
  animationLevel = 'premium',
  borderRadius,
  dropdownWidth = '16rem',
  dropdownMaxHeight = '80vh',
  elevated = true,
  visualEffects = {
    glassEffect: true,
    reflections: true,
    particleEffect: true,
    borderGlow: true,
    textGlow: true,
    metallic: true,
    flagWave: true,
    textReveal: true,
    gradientShift: true,
    shimmerEffect: true,
    textShadow: true,
    depthMapping: true,
    presenceAnimation: true,
    iconAnimation: true,
    materialDesign: 'luxury',
  },
  layoutMode = 'default',
  displayMode = 'premium',
  hoverType = 'glow',
  themingLevel = 'custom',
  transitionSpeed = 'elegant',
  interactionStyle = 'theatrical',
  focusIndicatorStyle = 'glow',
  presenceMode = 'elaborate',
  dropdownStagger = 0.04,
  metaTheme = 'finance',
  iconSet = 'flags',
  semanticLevel = 'high',
}) => {
  // Core hooks e stati
  const { i18n } = useTranslation();
  const { theme } = useTheme();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isTouching, setIsTouching] = useState<boolean>(false);
  const [hoveredLang, setHoveredLang] = useState<string | null>(null);
  const [selectedLang, setSelectedLang] = useState<string>(i18n.language);
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
  const [hasInteracted, setHasInteracted] = useState<boolean>(false);
  const [interactionCount, setInteractionCount] = useState<number>(0);
  const [mousePosition, setMousePosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isPerformingAction, setIsPerformingAction] = useState<boolean>(false);
  const [showHighlight, setShowHighlight] = useState<boolean>(false);
  const [lastSelectedLang, setLastSelectedLang] = useState<string | null>(null);
  const [dropdownHeight, setDropdownHeight] = useState<number>(0);
  const [shimmerAngle, setShimmerAngle] = useState<number>(135);
  const [shimmerPosition, setShimmerPosition] = useState<number>(0);
  const [isPristine, setIsPristine] = useState<boolean>(true);
  
  // Stati visuali avanzati
  const [transitionPhase, setTransitionPhase] = useState<'idle' | 'enter' | 'active' | 'exit'>('idle');
  const [effectIntensity, setEffectIntensity] = useState<number>(1);
  const [backgroundNoiseIntensity, setBackgroundNoiseIntensity] = useState<number>(0.03);
  const [currentLightPosition, setCurrentLightPosition] = useState<{ x: number; y: number }>({ x: 50, y: 50 });
  const [glowIntensity, setGlowIntensity] = useState<number>(0);
  const [glassTint, setGlassTint] = useState<number>(0.2);
  const [reflectionOpacity, setReflectionOpacity] = useState<number>(premiumVisualTheme.reflectionOpacity);
  const [buttonReady, setButtonReady] = useState<boolean>(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0);
  const [animation3DPhase, setAnimation3DPhase] = useState<number>(0);
  const [chromaticAberration, setChromaticAberration] = useState<number>(0);
  const [particleSystem, setParticleSystem] = useState<{ active: boolean; intensity: number }>({
    active: false,
    intensity: 0,
  });
  
  // Sistemi di animazione e interazione avanzati
  const [shimmerActive, toggleShimmerActive] = useCycle<boolean>(false, true);
  const [pulseActive, togglePulseActive] = useCycle<boolean>(false, true);
  const [revealState, cycleRevealState] = useCycle<'hidden' | 'visible' | 'highlighted'>('hidden', 'visible', 'highlighted');
  const [flagWaveActive, toggleFlagWave] = useCycle<boolean>(false, true);
  const [selectionRipple, setSelectionRipple] = useState<{ active: boolean; x: number; y: number; lang: string | null }>({
    active: false,
    x: 0,
    y: 0,
    lang: null,
  });
  
  // Controllers di animazione
  const buttonControls = useAnimation();
  const dropdownControls = useAnimation();
  const itemsControls = useAnimation();
  const glowControls = useAnimation();
  const reflectionControls = useAnimation();
  const rippleControls = useAnimation();
  const flagControls = useAnimation();
  const particleControls = useAnimation();
  const textRevealControls = useAnimation();
  const borderControls = useAnimation();
  const backgroundBlurControls = useAnimation();
  
  // Riferimenti DOM
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const itemsRef = useRef<Map<string, HTMLButtonElement>>(new Map());
  const mouseLeaveTimerRef = useRef<NodeJS.Timeout | null>(null);
  const rippleRef = useRef<HTMLDivElement>(null);
  const openTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const closeTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const interactionTimestampRef = useRef<number>(Date.now());
  const lastInteractionTypeRef = useRef<'click' | 'hover' | 'touch' | 'focus' | null>(null);
  const itemAnimationSequenceRef = useRef<number[]>([]);
  const observerRef = useRef<IntersectionObserver | null>(null);
  
  // Motion values e trasformazioni
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const scale = useMotionValue(1);
  const opacity = useMotionValue(1);
  const rotate = useMotionValue(0);
  const blur = useMotionValue(0);
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);
  const distance = useMotionValue(0);
  const hoverProgress = useMotionValue(0);
  const motionEffectIntensity = useMotionValue(1);
  const rotationAngle = useMotionValue(0);
  const highlightOpacity = useMotionValue(0);
  const selectIconOpacity = useMotionValue(0);
  const globalAnimationProgress = useMotionValue(0);
  const noiseValue = useMotionValue(0);
  const parallaxShiftX = useMotionValue(0);
  const parallaxShiftY = useMotionValue(0);
  
  // Trasformazioni avanzate dipendenti da motion values
  const animationProfile = useMemo(() => mapAnimationProfile(animationLevel), [animationLevel]);
  
  const rotateX = useTransform(
    y,
    [-100, 100],
    [animationProfile.rotation, -animationProfile.rotation]
  );
  
  const rotateY = useTransform(
    x,
    [-100, 100],
    [-animationProfile.rotation, animationProfile.rotation]
  );
  
  // Utilizzo di custom hook per combinare motion values
  // Versione compatibile per trasformazioni multiple
  function useCombinedMotionValues(
    input1: MotionValue<number>,
    input2: MotionValue<number>,
    transformFn: (val1: number, val2: number) => number
  ): MotionValue<number> {
    const output = useMotionValue(transformFn(input1.get(), input2.get()));
    
    useEffect(() => {
      const removeListener1 = input1.on("change", (val1) => {
        output.set(transformFn(val1, input2.get()));
      });
      
      const removeListener2 = input2.on("change", (val2) => {
        output.set(transformFn(input1.get(), val2));
      });
      
      return () => {
        removeListener1();
        removeListener2();
      };
    }, [input1, input2, transformFn, output]);
    
    return output;
  }
  
  const buttonScale = useCombinedMotionValues(
    scale, 
    hoverProgress,
    (s: number, h: number) => s * (1 + h * (animationProfile.scale - 1) * 0.5)
  );
  
  const shadowBlur = useCombinedMotionValues(
    hoverProgress,
    motionEffectIntensity,
    (h: number, i: number) => Math.max(6, 10 * h * i * animationProfile.intensity)
  );
  
  const shadowOpacity = useCombinedMotionValues(
    hoverProgress,
    motionEffectIntensity,
    (h: number, i: number) => Math.min(0.7, 0.15 + 0.4 * h * i * animationProfile.intensity)
  );
  
  const glowSize = useCombinedMotionValues(
    hoverProgress,
    motionEffectIntensity,
    (h: number, i: number) => Math.max(0, 8 * h * i * animationProfile.intensity)
  );
  
  const glowOpacity = useCombinedMotionValues(
    hoverProgress,
    motionEffectIntensity,
    (h: number, i: number) => Math.min(0.8, 0.1 + 0.5 * h * i * animationProfile.intensity)
  );
  
  const flagWaveIntensity = useCombinedMotionValues(
    motionEffectIntensity,
    globalAnimationProgress,
    (i: number, p: number) => Math.sin(p * Math.PI * 2) * 3 * i * animationProfile.complexity
  );
  
  const textRevealProgress = useTransform(
    globalAnimationProgress,
    [0, 0.4, 1],
    [0, 1, 1]
  );
  
  // Effetti trasformazione avanzati
  const chromaticOffset = useCombinedMotionValues(
    hoverProgress,
    motionEffectIntensity,
    (h: number, i: number) => h * i * 0.5 * animationProfile.complexity
  );
  
  const rotateXSpring = useSpring(rotateX, {
    stiffness: 400 * animationProfile.speed,
    damping: 25,
  });
  
  const rotateYSpring = useSpring(rotateY, {
    stiffness: 400 * animationProfile.speed,
    damping: 25,
  });
  
  const scaleSpring = useSpring(buttonScale, {
    stiffness: 500 * animationProfile.speed,
    damping: 25,
  });
  
  const blurSpring = useSpring(blur, {
    stiffness: 300 * animationProfile.speed,
    damping: 30,
  });
  
  const highlightXOffset = useTransform(
    mouseX,
    [0, 300],
    [-20, 20]
  );
  
  const highlightYOffset = useTransform(
    mouseY,
    [0, 60],
    [-10, 10]
  );
  
  const reflectionX = useTransform(
    mouseX,
    [0, 300],
    [-50, 50]
  );
  
  const reflectionY = useTransform(
    mouseY,
    [0, 60],
    [-30, 30]
  );
  
  const reflectionAngle = useCombinedMotionValues(
    reflectionX,
    reflectionY,
    (x: number, y: number) => Math.atan2(y, x) * (180 / Math.PI)
  );
  
  const reflectionDistance = useCombinedMotionValues(
    reflectionX,
    reflectionY,
    (x: number, y: number) => Math.sqrt(x * x + y * y)
  );
  
  // Per proprietà di riflessione che dipendono da valori calcolati, utilizziamo un approccio diverso
  const reflectionOpacityDynamic = useMotionValue(premiumVisualTheme.reflectionOpacity);
  
  // Aggiorniamo il valore in base a reflectionDistance e hoverProgress
  useEffect(() => {
    const updateReflectionOpacity = () => {
      const d = reflectionDistance.get();
      const h = hoverProgress.get();
      reflectionOpacityDynamic.set(
        Math.max(0.1, Math.min(premiumVisualTheme.reflectionOpacity, 0.2 + h * 0.4 + (1 - Math.min(1, d / 150)) * 0.3))
      );
    };
    
    const unsubscribeDistance = reflectionDistance.on("change", updateReflectionOpacity);
    const unsubscribeHover = hoverProgress.on("change", updateReflectionOpacity);
    
    return () => {
      unsubscribeDistance();
      unsubscribeHover();
    };
  }, [reflectionDistance, hoverProgress, premiumVisualTheme.reflectionOpacity]);
  
  // Template strings per CSS complesso
  const shimmerGradient = useMotionTemplate`linear-gradient(${shimmerAngle}deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) ${shimmerPosition}%, rgba(255,255,255,0) 100%)`;
  const buttonShadow = useMotionTemplate`0 ${shadowBlur}px ${shadowBlur}px rgba(0,0,0,${shadowOpacity})`;
  const buttonGlow = useMotionTemplate`0 0 ${glowSize}px rgba(255,255,255,${glowOpacity})`;
  const textShadowTemplate = useMotionTemplate`0 1px 2px rgba(0,0,0,${shadowOpacity})`;
  const redOffset = useMotionTemplate`${chromaticOffset}px`;
  const blueOffset = useMotionTemplate`-${chromaticOffset}px`;
  const rotateTemplate = useMotionTemplate`rotateX(${rotateXSpring}deg) rotateY(${rotateYSpring}deg)`;
  
  // Sincronizzazione con stato lingua i18n
  useEffect(() => {
    if (selectedLang !== i18n.language) {
      setLastSelectedLang(selectedLang);
      setSelectedLang(i18n.language);
      setSelectedItemIndex(languages.findIndex(l => l.code === i18n.language));
      
      // Forza l'aggiornamento del pulsante quando cambia la lingua
      const newLanguageGradient = languages.find(l => l.code === i18n.language)?.gradient;
      if (newLanguageGradient) {
        buttonControls.set({
          background: newLanguageGradient,
        });
      }
      
      // Aggiorna l'aspetto visivo con un effetto speciale di transizione
      buttonControls.start({
        scale: [1, 0.95, 1.05, 1],
        transition: {
          duration: 0.8,
          times: [0, 0.3, 0.6, 1],
          ease: [0.22, 1, 0.36, 1],
        }
      });
      
      // Attiva l'effetto shimmer per segnalare il cambio
      if (shimmerPosition === 0) {
        setShimmerPosition(50);
      } else {
        setShimmerPosition(0);
        setTimeout(() => {
          setShimmerPosition(50);
        }, 50);
      }
    }
  }, [i18n.language, selectedLang, buttonControls, languages, shimmerPosition]);
  
  // Configurazione dimensioni responsive
  const sizeClasses = useMemo(() => {
    switch (buttonSize) {
      case 'xs':
        return {
          button: 'px-1.5 py-0.5 text-xs',
          flag: 'text-base mr-1',
          text: 'text-xs',
          dropdown: 'text-xs py-0.5',
          iconSize: 12,
          height: 28,
          padding: 4,
        };
      case 'sm':
        return {
          button: 'px-2 py-1 text-xs',
          flag: 'text-lg mr-1.5',
          text: 'text-xs',
          dropdown: 'text-xs py-1',
          iconSize: 14,
          height: 32,
          padding: 6,
        };
      case 'lg':
        return {
          button: 'px-5 py-3 text-base',
          flag: 'text-3xl mr-3',
          text: 'text-base',
          dropdown: 'text-base py-2',
          iconSize: 20,
          height: 48,
          padding: 12,
        };
      case 'xl':
        return {
          button: 'px-6 py-4 text-lg',
          flag: 'text-4xl mr-4',
          text: 'text-lg',
          dropdown: 'text-lg py-3',
          iconSize: 24,
          height: 56,
          padding: 16,
        };
      case 'md':
      default:
        return {
          button: 'px-4 py-2 text-sm',
          flag: 'text-2xl mr-2',
          text: 'text-sm',
          dropdown: 'text-sm py-1.5',
          iconSize: 16,
          height: 40,
          padding: 8,
        };
    }
  }, [buttonSize]);
  
  // Posizionamento dropdown basato su proprietà
  const dropdownPositionClasses = useMemo(() => {
    switch (dropdownPosition) {
      case 'left':
        return 'left-0 origin-top-left';
      case 'center':
        return 'left-1/2 -translate-x-1/2 origin-top';
      case 'right':
      default:
        return 'right-0 origin-top-right';
    }
  }, [dropdownPosition]);
  
  // Stili personalizzati in base alle props
  const customStyles = useMemo(() => {
    const styles: Record<string, any> = {};
    
    if (borderRadius) {
      styles.borderRadius = borderRadius;
    }
    
    return styles;
  }, [borderRadius]);
  
  // Impostazioni animazione in base alla velocità
  const transitionTimings = useMemo(() => {
    const baseSpeed = premiumVisualTheme.animationTiming[transitionSpeed === 'elegant' ? 'elegant' : transitionSpeed === 'slow' ? 'slow' : transitionSpeed === 'fast' ? 'fast' : 'normal'];
    
    return {
      base: baseSpeed,
      staggerChildren: dropdownStagger,
      staggerDirection: 1,
      delayChildren: baseSpeed * 0.2,
    };
  }, [transitionSpeed, dropdownStagger]);
  
  // Lingua corrente con fallback intelligente
  const currentLanguage = useMemo(() => {
    return languages.find((lang) => lang.code === selectedLang) || languages[0];
  }, [selectedLang]);
  
  // Stili di lusso per la lingua corrente
  const currentLuxuryStyles = useMemo(() => {
    return generateLuxuryStyles(currentLanguage.luxuryVariant || 'platinum', theme as string);
  }, [currentLanguage.luxuryVariant, theme]);
  
  // Varianti di animazione dropdown
  const dropdownVariants: Variants = {
    hidden: {
      opacity: 0,
      y: -10,
      scale: 0.95,
      clipPath: 'inset(0% 0% 100% 0%)',
      transition: {
        duration: transitionTimings.base * 0.5,
        ease: [0.4, 0.0, 0.2, 1],
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      clipPath: 'inset(0% 0% 0% 0%)',
      transition: {
        type: 'spring',
        stiffness: 400,
        damping: 30,
        staggerChildren: transitionTimings.staggerChildren,
        delayChildren: transitionTimings.delayChildren,
        staggerDirection: transitionTimings.staggerDirection,
      },
    },
    exit: {
      opacity: 0,
      y: -5,
      scale: 0.97,
      clipPath: 'inset(0% 0% 100% 0%)',
      transition: {
        duration: transitionTimings.base * 0.5,
        ease: [0.4, 0.0, 0.2, 1],
      },
    },
  };
  
  // Varianti di animazione elementi dropdown
  const itemVariants: Variants = {
    hidden: { 
      opacity: 0, 
      x: -10,
      scaleX: 0.95,
      filter: 'blur(4px)',
      transition: { 
        duration: transitionTimings.base * 0.3,
        ease: 'easeOut',
      } 
    },
    visible: { 
      opacity: 1, 
      x: 0,
      scaleX: 1,
      filter: 'blur(0px)',
      transition: { 
        type: 'spring', 
        stiffness: 500, 
        damping: 30,
        mass: 0.8, 
      } 
    },
    exit: { 
      opacity: 0, 
      x: -5,
      scaleX: 0.97,
      filter: 'blur(2px)',
      transition: { 
        duration: transitionTimings.base * 0.3,
        ease: 'easeIn',
      } 
    },
    hover: { 
      x: 10, 
      transition: { 
        duration: transitionTimings.base * 0.4,
        ease: [0.22, 1, 0.36, 1],
      } 
    },
    selected: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      backdropFilter: 'blur(2px)',
      transition: {
        duration: transitionTimings.base * 0.6,
        ease: [0.22, 1, 0.36, 1],
      }
    }
  };
  
  // Varianti animazione freccia
  const arrowVariants: Variants = {
    closed: { 
      rotate: 0, 
      scale: 1,
      y: 0,
      transition: {
        type: 'spring', 
        stiffness: 500, 
        damping: 25
      } 
    },
    open: { 
      rotate: 180, 
      scale: 1.1,
      y: -2,
      transition: {
        type: 'spring', 
        stiffness: 500, 
        damping: 25
      } 
    },
  };
  
  // Varianti animazione particelle
  const particleVariants: Variants = {
    initial: { 
      scale: 0, 
      opacity: 0.8,
      filter: 'blur(0px)',
    },
    animate: { 
      scale: [0, 1.5, 2],
      opacity: [0.8, 0.5, 0],
      filter: ['blur(0px)', 'blur(2px)', 'blur(4px)'],
      transition: { 
        duration: 1.2 * animationProfile.speed, 
        ease: [0.22, 1, 0.36, 1],
        times: [0, 0.4, 1],
      }
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.3 * animationProfile.speed,
      }
    }
  };
  
  // Varianti animazione bordo
  const borderVariants: Variants = {
    idle: {
      opacity: 0,
      pathLength: 0,
    },
    active: {
      opacity: 1,
      pathLength: 1,
      transition: {
        pathLength: {
          type: 'spring',
          duration: 1 * animationProfile.speed,
          bounce: 0.3,
        },
        opacity: {
          duration: 0.4 * animationProfile.speed,
        }
      }
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.5 * animationProfile.speed
      }
    }
  };
  
  // Varianti animazione brillantini
  const shimmerVariants: Variants = {
    initial: {
      backgroundPosition: '-100% 0%',
      opacity: 0,
    },
    animate: {
      backgroundPosition: ['-100% 0%', '200% 0%'],
      opacity: [0, 0.7, 0],
      transition: {
        backgroundPosition: {
          times: [0, 1],
          duration: transitionTimings.base * 3,
          ease: 'linear',
          repeat: Infinity,
          repeatType: 'loop',
          repeatDelay: transitionTimings.base * 2,
        },
        opacity: {
          times: [0, 0.5, 1],
          duration: transitionTimings.base * 3,
          ease: 'easeInOut',
          repeat: Infinity,
          repeatType: 'loop',
          repeatDelay: transitionTimings.base * 2,
        }
      }
    },
  };
  
  // Varianti animazione riflessioni
  const reflectionVariants: Variants = {
    idle: {
      opacity: 0.1,
      rotate: 35,
      scale: 1,
    },
    active: {
      opacity: reflectionOpacity,
      rotate: [35, 45, 35],
      scale: [1, 1.05, 1],
      transition: {
        rotate: {
          duration: 8,
          ease: 'easeInOut',
          repeat: Infinity,
          repeatType: 'reverse',
        },
        scale: {
          duration: 5,
          ease: 'easeInOut',
          repeat: Infinity,
          repeatType: 'reverse',
        },
        opacity: {
          duration: 0.6,
        }
      }
    },
    hover: {
      opacity: 0.6,
      rotate: 45,
      scale: 1.1,
      transition: {
        duration: 0.4,
      }
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.4,
      }
    }
  };
  
  // Varianti animazione bagliore
  const glowVariants: Variants = {
    idle: {
      opacity: 0,
      scale: 0.9,
    },
    hover: {
      opacity: 0.7,
      scale: 1.1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
      }
    },
    press: {
      opacity: 0.8,
      scale: 1.05,
      transition: {
        duration: 0.2,
        ease: 'easeOut',
      }
    },
    exit: {
      opacity: 0,
      scale: 0.95,
      transition: {
        duration: 0.3,
        ease: 'easeIn',
      }
    }
  };
  
  // Varianti animazione bandiera
  const flagWaveVariants: Variants = {
    idle: {
      skew: 0,
      scale: 1,
    },
    wave: {
      skew: [0, 2, -2, 0],
      scale: [1, 1.03, 1, 1.02, 1],
      transition: {
        duration: 1.8,
        ease: 'easeInOut',
        times: [0, 0.25, 0.5, 0.75, 1],
      }
    },
    constant: {
      skew: [0, 1, -1, 0],
      scale: [1, 1.01, 1, 1.01, 1],
      transition: {
        duration: 3,
        ease: 'easeInOut',
        times: [0, 0.25, 0.5, 0.75, 1],
        repeat: Infinity,
        repeatType: 'mirror',
      }
    }
  };
  
  // Varianti animazione ripple selettore
  const rippleVariants: Variants = {
    initial: {
      opacity: 0.5,
      scale: 0,
    },
    animate: {
      opacity: [0.5, 0.4, 0],
      scale: [0, 0.8, 1],
      transition: {
        duration: 0.8 * animationProfile.speed,
        ease: [0.22, 1, 0.36, 1],
      }
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.2 * animationProfile.speed,
      }
    }
  };
  
  // Varianti animazione testo
  const textRevealVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 10,
      filter: 'blur(8px)',
    },
    visible: {
      opacity: 1,
      y: 0,
      filter: 'blur(0px)',
      transition: {
        duration: 0.5 * animationProfile.speed,
        ease: [0.22, 1, 0.36, 1],
      }
    },
    exit: {
      opacity: 0,
      y: -5,
      filter: 'blur(4px)',
      transition: {
        duration: 0.3 * animationProfile.speed,
        ease: 'easeIn',
      }
    }
  };
  
  // Varianti animazione contenitore
  const containerVariants: Variants = {
    idle: {
      scale: 1,
    },
    hover: {
      scale: 1.02,
      transition: {
        duration: 0.4,
        ease: [0.22, 1, 0.36, 1],
      }
    },
    tap: {
      scale: 0.98,
      transition: {
        duration: 0.2,
        ease: [0.22, 1, 0.36, 1],
      }
    }
  };
  
  // Gestione avanzata dello stato aperto/chiuso
  const toggleDropdown = useCallback((e: React.MouseEvent | React.TouchEvent) => {
    e.stopPropagation();
    setHasInteracted(true);
    setInteractionCount(prev => prev + 1);
    setIsPristine(false);
    lastInteractionTypeRef.current = 'click';
    interactionTimestampRef.current = Date.now();
    
    // Cancellazione timeout esistenti
    if (openTimeoutRef.current) {
      clearTimeout(openTimeoutRef.current);
      openTimeoutRef.current = null;
    }
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
      closeTimeoutRef.current = null;
    }
    
    // Attivazione effetti al click
    togglePulseActive();
    setShowHighlight(true);
    setTimeout(() => setShowHighlight(false), 600);
    
    if (!isOpen) {
      // Set di proprietà per apertura
      setGlowIntensity(0.7);
      setEffectIntensity(1.5);
      setTransitionPhase('enter');
      setIsPerformingAction(true);
      
      // Animazioni varie
      buttonControls.start({
        scale: 1.05,
        transition: {
          duration: 0.3,
          ease: [0.22, 1, 0.36, 1],
        }
      });
      
      // Sistema particelle
      setParticleSystem({
        active: true,
        intensity: 1,
      });
      
      // Attivazione effetto metallico
      if (visualEffects.metallic) {
        borderControls.start('active');
      }
      
      // Avvio animazione riflessi
      if (visualEffects.reflections) {
        reflectionControls.start('active');
      }
      
      // Apertura dropdown con timing
      openTimeoutRef.current = setTimeout(() => {
        setIsOpen(true);
        setTransitionPhase('active');
        
        // Resetta stati dopo timeout
        setTimeout(() => {
          setIsPerformingAction(false);
          setEffectIntensity(1);
          particleControls.start('initial');
        }, 600);
      }, 50);
    } else {
      // Set di proprietà per chiusura
      setIsPerformingAction(true);
      setTransitionPhase('exit');
      
      // Animazioni varie per chiusura
      buttonControls.start({
        scale: 0.98,
        transition: {
          duration: 0.2,
          ease: [0.22, 1, 0.36, 1],
        }
      }).then(() => {
        buttonControls.start({
          scale: 1,
          transition: {
            duration: 0.3,
            ease: [0.22, 1, 0.36, 1],
          }
        });
      });
      
      if (visualEffects.borderGlow) {
        borderControls.start('exit');
      }
      
      // Chiusura dropdown con timing
      closeTimeoutRef.current = setTimeout(() => {
        setIsOpen(false);
        setTransitionPhase('idle');
        
        // Resetta stati dopo timeout
        setTimeout(() => {
          setIsPerformingAction(false);
          setParticleSystem({
            active: false,
            intensity: 0,
          });
        }, 300);
      }, 100);
    }
  }, [isOpen, visualEffects.metallic, visualEffects.reflections, visualEffects.borderGlow, borderControls, buttonControls, reflectionControls, particleControls, togglePulseActive]);
  
  // Cambio lingua con effetti avanzati
  const changeLanguage = useCallback((lng: string, e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
    if (lng !== selectedLang && !isTransitioning) {
      // Tracciamento stato
      setHasInteracted(true);
      setIsPristine(false);
      setIsTransitioning(true);
      interactionTimestampRef.current = Date.now();
      lastInteractionTypeRef.current = 'click';
      
      // Memorizza lingua precedente
      setLastSelectedLang(selectedLang);
      
      // Aggiornamento lingua
      i18n.changeLanguage(lng);
      setSelectedLang(lng);
      setSelectedItemIndex(languages.findIndex(l => l.code === lng));
      
      // Forza l'aggiornamento del pulsante per mostrare la nuova bandiera
      const newLanguage = languages.find(l => l.code === lng);
      if (newLanguage) {
        buttonControls.set({
          background: newLanguage.gradient
        });
      }
      
      // Attivazione effetti
      if (visualEffects.shimmerEffect) {
        toggleShimmerActive();
      }
      
      // Sistema particelle
      setParticleSystem({
        active: true,
        intensity: 1,
      });
      
      // Animazioni varie
      buttonControls.start({
        scale: [1, 0.95, 1.05, 1],
        transition: {
          duration: 0.8,
          times: [0, 0.3, 0.6, 1],
          ease: [0.22, 1, 0.36, 1],
        }
      });
      
      // Animazione di selezione
      setSelectionRipple({
        active: true,
        x: (e as React.MouseEvent).clientX || 0,
        y: (e as React.MouseEvent).clientY || 0,
        lang: lng,
      });
      
      // Chiusura dropdown ritardata per migliore percezione
      setTimeout(() => {
        setIsOpen(false);
        setSelectionRipple({
          active: false,
          x: 0,
          y: 0,
          lang: null,
        });
        setIsTransitioning(false);
        
        // Callback se definita
        if (onLanguageChange) {
          onLanguageChange(lng);
        }
      }, transitionSpeed === 'elegant' ? 600 : transitionSpeed === 'slow' ? 500 : transitionSpeed === 'fast' ? 250 : 400);
    } else if (lng === selectedLang) {
      // Se stessa lingua, chiudi semplicemente
      setIsOpen(false);
    }
  }, [selectedLang, isTransitioning, i18n, toggleShimmerActive, visualEffects.shimmerEffect, buttonControls, onLanguageChange, transitionSpeed, languages]);
  
  // Gestione movimento mouse per effetti 3D
  const handleMouseMove = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    if (!buttonRef.current) return;
    
    const rect = event.currentTarget.getBoundingClientRect();
    
    // Calcolo centrato per effetto 3D
    const centerX = event.clientX - rect.left - rect.width / 2;
    const centerY = event.clientY - rect.top - rect.height / 2;
    
    // Tracciamento posizione per vari effetti
    x.set(centerX);
    y.set(centerY);
    
    // Posizione relativa per effetto highlight
    mouseX.set(event.clientX - rect.left);
    mouseY.set(event.clientY - rect.top);
    
    // Tracciamento per effetti lighting
    setMousePosition({
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    });
    
    // Impostazione angolo shimmer in base a movimento
    const angle = Math.atan2(centerY, centerX) * (180 / Math.PI);
    setShimmerAngle(angle + 135);
    
    // Posizione luce dinamica
    setCurrentLightPosition({
      x: (event.clientX - rect.left) / rect.width * 100,
      y: (event.clientY - rect.top) / rect.height * 100,
    });
    
    // Regolazione intensità effetti in base alla distanza dal centro
    const distance = Math.sqrt(centerX * centerX + centerY * centerY);
    const normalizedDistance = Math.min(1, distance / Math.max(rect.width, rect.height));
    motionEffectIntensity.set(1 + normalizedDistance * 0.2);
    
    // Calcolo aberrazione cromatica
    setChromaticAberration(normalizedDistance * 1.2);
  }, [x, y, mouseX, mouseY, motionEffectIntensity]);
  
  // Reset stato mouse su uscita
  const handleMouseLeave = useCallback(() => {
    // Animazione di ritorno alla posizione neutra
    x.set(0);
    y.set(0);
    setIsHovering(false);
    hoverProgress.set(0);
    motionEffectIntensity.set(1);
    setChromaticAberration(0);
    
    // Reset posizioni
    setCurrentLightPosition({ x: 50, y: 50 });
    
    // Animazioni di uscita
    if (isOpen) return;
    
    if (visualEffects.reflections) {
      reflectionControls.start('idle');
    }
    
    if (visualEffects.borderGlow) {
      borderControls.start('idle');
    }
    
    if (visualEffects.glassEffect) {
      backgroundBlurControls.start({
        filter: 'blur(0px)',
        transition: { duration: 0.4 }
      });
    }
    
    // Cancella timeout esistente
    if (mouseLeaveTimerRef.current) {
      clearTimeout(mouseLeaveTimerRef.current);
    }
    
    // Ritardo reset per evitare flickering quando utente oscilla sul bordo
    mouseLeaveTimerRef.current = setTimeout(() => {
      if (!isOpen) {
        setGlowIntensity(0);
        setParticleSystem({
          active: false,
          intensity: 0,
        });
      }
    }, 300);
  }, [x, y, hoverProgress, motionEffectIntensity, isOpen, visualEffects.reflections, visualEffects.borderGlow, visualEffects.glassEffect, backgroundBlurControls, reflectionControls, borderControls]);
  
  // Gestione hover
  const handleMouseEnter = useCallback(() => {
    setIsHovering(true);
    hoverProgress.set(1);
    lastInteractionTypeRef.current = 'hover';
    interactionTimestampRef.current = Date.now();
    
    // Cancella eventuale timeout di leave
    if (mouseLeaveTimerRef.current) {
      clearTimeout(mouseLeaveTimerRef.current);
      mouseLeaveTimerRef.current = null;
    }
    
    // Applicazione effetti hover
    setGlowIntensity(0.4);
    
    if (!isOpen && !isPerformingAction) {
      // Solo se non sono in corso altre azioni
      if (visualEffects.reflections) {
        reflectionControls.start('hover');
      }
      
      if (visualEffects.glassEffect) {
        backgroundBlurControls.start({
          filter: 'blur(4px)',
          transition: { duration: 0.4 }
        });
      }
      
      // Attivazione effetto bandiera
      if (visualEffects.flagWave && !flagWaveActive) {
        flagControls.start('wave');
        setTimeout(() => {
          flagControls.start('constant');
        }, 1800);
      }
      
      // Attivazione shimmer
      if (visualEffects.shimmerEffect && interactionCount > 0 && Math.random() > 0.5) {
        setShimmerPosition(0);
        setTimeout(() => {
          setShimmerPosition(50);
        }, 50);
      }
    }
  }, [
    hoverProgress, 
    isOpen,
    isPerformingAction,
    interactionCount,
    flagWaveActive,
    visualEffects.reflections,
    visualEffects.glassEffect,
    visualEffects.flagWave,
    visualEffects.shimmerEffect,
    reflectionControls,
    backgroundBlurControls,
    flagControls
  ]);
  
  // Gestione tap/touch
  const handleTouchStart = useCallback(() => {
    setIsTouching(true);
    lastInteractionTypeRef.current = 'touch';
    interactionTimestampRef.current = Date.now();
    buttonControls.start("tap");
    
    // Attivazione effetti touch
    setGlowIntensity(0.5);
    setEffectIntensity(1.2);
    
    // Sistema particelle
    setParticleSystem({
      active: true,
      intensity: 0.7,
    });
  }, [buttonControls]);
  
  // Gestione fine tap/touch
  const handleTouchEnd = useCallback(() => {
    setIsTouching(false);
    buttonControls.start("idle");
    
    // Riduci effetti gradualmente
    setTimeout(() => {
      if (!isOpen) {
        setGlowIntensity(0);
        setEffectIntensity(1);
        setParticleSystem({
          active: false,
          intensity: 0,
        });
      }
    }, 300);
  }, [buttonControls, isOpen]);
  
  // Gestione avanzata clic fuori dal dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && 
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target as Node) &&
          buttonRef.current &&
          !buttonRef.current.contains(event.target as Node)) {
        
        setIsOpen(false);
        setTransitionPhase('exit');
        
        // Animazioni di chiusura
        buttonControls.start({
          scale: 1,
          transition: {
            duration: 0.2,
            ease: [0.22, 1, 0.36, 1],
          }
        });
        
        // Reset stati vari
        setTimeout(() => {
          setTransitionPhase('idle');
          setParticleSystem({
            active: false,
            intensity: 0,
          });
        }, 300);
      }
    };
    
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isOpen, buttonControls]);
  
  // Sistema di accessibilità keyboard
  useEffect(() => {
    if (!isOpen) return;
    
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!isOpen) return;
      
      switch (event.key) {
        case 'Escape':
          setIsOpen(false);
          setTransitionPhase('exit');
          buttonRef.current?.focus();
          
          // Animazioni di chiusura
          buttonControls.start({
            scale: 1,
            transition: {
              duration: 0.2,
              ease: [0.22, 1, 0.36, 1],
            }
          });
          
          // Reset stati
          setTimeout(() => {
            setTransitionPhase('idle');
            setParticleSystem({
              active: false,
              intensity: 0,
            });
          }, 300);
          break;
          
        case 'ArrowDown':
        case 'ArrowUp': {
          event.preventDefault();
          
          const langItems = languages.map((lang) => lang.code);
          const currentIndex = langItems.indexOf(selectedLang);
          let nextIndex: number;
          
          if (event.key === 'ArrowDown') {
            nextIndex = (currentIndex + 1) % langItems.length;
          } else {
            nextIndex = (currentIndex - 1 + langItems.length) % langItems.length;
          }
          
          // Focus sull'elemento e animazione
          const nextLangButton = itemsRef.current.get(langItems[nextIndex]);
          if (nextLangButton) {
            nextLangButton?.focus();
            itemsControls.start((i) => ({
              x: i === nextIndex ? 10 : 0,
              transition: {
                duration: 0.3,
                ease: [0.22, 1, 0.36, 1],
              }
            }));
          }
          break;
        }
          
        case 'Tab': {
          // Gestione focus trap per accessibilità
          const langButtons = Array.from(itemsRef.current.values());
          const firstButton = langButtons[0];
          const lastButton = langButtons[langButtons.length - 1];
          
          if (event.shiftKey && document.activeElement === firstButton) {
            event.preventDefault();
            setIsOpen(false);
            buttonRef.current?.focus();
          } else if (!event.shiftKey && document.activeElement === lastButton) {
            setIsOpen(false);
          }
          break;
        }
      }
    };
    
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, selectedLang, buttonControls, itemsControls]);
  
  // Gestione focus
  const handleFocus = useCallback(() => {
    setIsFocused(true);
    lastInteractionTypeRef.current = 'focus';
    
    // Applicazione stili focus
    if (focusIndicatorStyle === 'glow') {
      setGlowIntensity(0.3);
      
      if (visualEffects.borderGlow) {
        borderControls.start('active');
      }
    }
  }, [focusIndicatorStyle, visualEffects.borderGlow, borderControls]);
  
  const handleBlur = useCallback(() => {
    setIsFocused(false);
    
    // Reset stili focus
    if (!isOpen) {
      setGlowIntensity(0);
      
      if (visualEffects.borderGlow) {
        borderControls.start('idle');
      }
    }
  }, [isOpen, visualEffects.borderGlow, borderControls]);
  
  // Ottimizzazione colore testo per contrasto
  const getOptimalTextColor = useCallback((bgGradient: string) => {
    // Usa colore predefinito se disponibile
    if (currentLanguage.textColor) {
      return currentLanguage.textColor;
    }
    
    // Default in base al tema
    return theme === 'light' ? 'text-gray-900' : 'text-white';
  }, [currentLanguage.textColor, theme]);
  
  // Generazione classi shadow in base a prop elevated e stato
  const getShadowClasses = useCallback(() => {
    if (!elevated) return '';
    
    // Shadow in base a stato interattivo
    if (isTouching) {
      return 'shadow-lg';
    }
    
    if (isOpen) {
      return 'shadow-xl';
    }
    
    if (isHovering || isFocused) {
      return 'shadow-lg';
    }
    
    // Default shadow
    return 'shadow-md';
  }, [elevated, isOpen, isHovering, isFocused, isTouching]);
  
  // Animazione shimmer
  useEffect(() => {
    if (visualEffects.shimmerEffect && !isPristine && !isOpen) {
      const shimmerInterval = setInterval(() => {
        // Casuale se attivare shimmer 
        if (Math.random() > 0.7) {
          setShimmerPosition(0);
          setTimeout(() => {
            setShimmerPosition(50);
          }, 50);
        }
      }, 5000);
      
      return () => clearInterval(shimmerInterval);
    }
  }, [visualEffects.shimmerEffect, isPristine, isOpen]);
  
  // Animazione particelle
  useEffect(() => {
    if (visualEffects.particleEffect && particleSystem.active) {
      particleControls.start('animate');
    } else {
      particleControls.start('initial');
    }
  }, [visualEffects.particleEffect, particleSystem.active, particleControls]);
  
  // Animazione movimento globale
  useEffect(() => {
    // Timer per simulare animazione continua globale
    const progressInterval = setInterval(() => {
      const newValue = (Date.now() % 6000) / 6000;
      globalAnimationProgress.set(newValue);
    }, 50);
    
    return () => clearInterval(progressInterval);
  }, [globalAnimationProgress]);
  
  // Inizializzazione immediata e caricamento forzato delle bandiere
  useEffect(() => {
    // Forza l'attivazione del pulsante con la lingua corrente
    if (buttonControls && languages.length > 0) {
      // Trova la lingua corrente dall'url o dall'impostazione i18n
      const currentLangCode = i18n.language;
      const lang = languages.find(l => l.code === currentLangCode) || languages[0];
      
      // Imposta immediatamente i valori corretti
      setSelectedLang(currentLangCode);
      buttonControls.set({
        scale: 1
      });
      
      // Applica un'animazione sottile per garantire il rendering completo
      setTimeout(() => {
        buttonControls.start({
          scale: [1, 1.01, 1],
          transition: { duration: 0.6 }
        });
      }, 100);
    }
  }, [buttonControls, languages, i18n.language]);
  
  // Ripulitura timeout su unmount
  useEffect(() => {
    return () => {
      if (mouseLeaveTimerRef.current) {
        clearTimeout(mouseLeaveTimerRef.current);
      }
      if (openTimeoutRef.current) {
        clearTimeout(openTimeoutRef.current);
      }
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
    };
  }, []);
  
  // Classi di testo e stile per il bottone
  const textColor = getOptimalTextColor(currentLanguage.gradient);
  
  // Stili preimpostati in base al tema
  const themeStyles = useMemo(() => {
    return {
      buttonBase: theme === 'light' 
        ? 'ring-gray-200 bg-opacity-90' 
        : 'ring-gray-800 bg-opacity-80',
      dropdownBase: theme === 'light'
        ? 'bg-white shadow-xl ring-1 ring-gray-200'
        : 'bg-gray-900 shadow-xl ring-1 ring-gray-800',
      itemHover: theme === 'light'
        ? 'hover:bg-gray-50'
        : 'hover:bg-gray-800',
      itemSelected: theme === 'light'
        ? 'bg-blue-50 text-blue-900'
        : 'bg-blue-900 text-blue-50',
    };
  }, [theme]);
  
  // Stile avanzato per il pulsante con controllo visuale sempre attivo
  const buttonStaticStyle = useMemo(() => {
    return {
      background: `linear-gradient(to right, rgba(255,255,255,0.9), rgba(255,255,255,0.9))`,
      boxShadow: '0 2px 4px rgba(0,0,0,0.08)',
      border: '1px solid rgba(220,220,220,0.7)',
      transform: 'translateZ(0.01px)',
      backdropFilter: 'blur(8px)',
    };
  }, []);
  
  // Stile per la bandiera inclusa nello sfondo del pulsante
  const flagBackgroundStyle = useMemo(() => {
    // Estrae la bandiera dalla lingua corrente
    return {
      background: `${currentLanguage.gradient}`,
      opacity: 0.85,
      borderRadius: '50%',
      width: '2.2em',
      height: '2.2em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.15)',
      border: '1px solid rgba(255,255,255,0.6)',
    };
  }, [currentLanguage.gradient]);
  
  // Generazione classe dropdown posizione
  const dropdownClasses = `
    absolute
    ${dropdownPositionClasses}
    mt-2
    overflow-hidden
    z-[2001]
    ${themeStyles.dropdownBase}
    backdrop-blur-sm
    backdrop-filter
  `;
  
  // Effetto vetro/neomorfismo
  const glassEffect = useMemo(() => {
    if (!visualEffects.glassEffect) return {};
    
    return theme === 'light'
      ? {
        background: 'rgba(255, 255, 255, 0.7)',
        backdropFilter: 'blur(10px)',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), inset 0 1px 0 rgba(255, 255, 255, 0.4)',
      }
      : {
        background: 'rgba(30, 30, 30, 0.7)',
        backdropFilter: 'blur(10px)',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2), inset 0 1px 0 rgba(255, 255, 255, 0.1)',
      };
  }, [visualEffects.glassEffect, theme]);
  
  // Sistema di particelle avanzato
  const ParticleSystem = () => {
    if (!visualEffects.particleEffect || !particleSystem.active) return null;
    
    return (
      <AnimatePresence>
        <motion.div
          className="absolute inset-0 pointer-events-none overflow-hidden rounded-full"
          style={{ 
            opacity: particleSystem.intensity,
            zIndex: 1,
          }}
        >
          {[...Array(premiumVisualTheme.particleDensity)].map((_, i) => (
            <motion.div
              key={`particle-${i}`}
              className="absolute rounded-full bg-white/20"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                width: `${Math.random() * 15 + 5}px`,
                height: `${Math.random() * 15 + 5}px`,
                zIndex: 1,
              }}
              variants={particleVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{
                duration: 1.2 + Math.random() * 0.5,
                delay: Math.random() * 0.3,
                ease: [0.22, 1, 0.36, 1],
              }}
            />
          ))}
        </motion.div>
      </AnimatePresence>
    );
  };
  
  // Componente bordo animato
  const BorderGlow = () => {
    if (!visualEffects.borderGlow) return null;
    
    const borderColor = currentLuxuryStyles.borderGradient;
    
    return (
      <div className="absolute inset-0 rounded-full overflow-hidden pointer-events-none" style={{ zIndex: 1 }}>
        <motion.div
          className="absolute inset-0"
          style={{
            background: borderColor,
            opacity: 0,
            zIndex: 1,
          }}
          variants={borderVariants}
          initial="idle"
          animate={isOpen || isFocused ? "active" : "idle"}
        />
      </div>
    );
  };
  
  // Componente effetto highlight
  const HighlightEffect = () => (
    <motion.div
      className="absolute inset-0 rounded-full pointer-events-none"
      style={{
        background: `radial-gradient(circle at ${currentLightPosition.x}% ${currentLightPosition.y}%, rgba(255,255,255,0.8) 0%, transparent 70%)`,
        opacity: showHighlight ? 0.6 : isHovering ? 0.3 : 0,
        zIndex: 2,
        transition: 'opacity 0.3s ease-out',
      }}
    />
  );
  
  // Componente effetto riflessi
  const ReflectionEffect = () => {
    if (!visualEffects.reflections) return null;
    
    return (
      <motion.div
        className="absolute inset-0 rounded-full overflow-hidden pointer-events-none"
        style={{ 
          zIndex: 3, 
          mixBlendMode: 'soft-light',
          opacity: 0,
        }}
        variants={reflectionVariants}
        initial="idle"
        animate={isHovering ? "hover" : isOpen ? "active" : "idle"}
      >
        <div 
          className="absolute inset-0" 
          style={{ 
            background: 'linear-gradient(135deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,0.1) 100%)',
            transform: `rotate(${45 + animation3DPhase * 10}deg)`,
          }}
        />
      </motion.div>
    );
  };
  
  // Componente effetto shimmer
  const ShimmerEffect = () => {
    if (!visualEffects.shimmerEffect) return null;
    
    return (
      <motion.div
        className="absolute inset-0 rounded-full pointer-events-none overflow-hidden"
        style={{
          background: shimmerGradient,
          backgroundSize: '200% 100%',
          zIndex: 4,
        }}
        variants={shimmerVariants}
        initial="initial"
        animate={shimmerActive ? "animate" : "initial"}
      />
    );
  };
  
  // Componente ripple di selezione
  const SelectionRipple = () => {
    if (!selectionRipple.active) return null;
    
    // Trova la lingua selezionata
    const selectedLangObj = languages.find(lang => lang.code === selectionRipple.lang);
    if (!selectedLangObj) return null;
    
    // Colore basato sulla lingua selezionata
    const rippleColor = selectedLangObj.accentColor || '#4F46E5';
    
    return (
      <motion.div
        className="fixed pointer-events-none"
        style={{
          left: selectionRipple.x,
          top: selectionRipple.y,
          zIndex: 9999,
          width: 300,
          height: 300,
          borderRadius: '50%',
          marginLeft: -150,
          marginTop: -150,
          background: rippleColor,
          opacity: 0,
        }}
        variants={rippleVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      />
    );
  };
  
  // Rendering del componente
  return (
    <motion.div 
      className={`relative inline-block z-[2000] ${className}`} 
      ref={containerRef}
      aria-label="Selettore lingue"
      variants={containerVariants}
      initial="idle"
      animate={isHovering ? "hover" : isTouching ? "tap" : "idle"}
      style={{
        perspective: 1000,
        transformStyle: 'preserve-3d',
      }}
    >
      {/* Selettore lingua con effetti 3D */}
      <motion.div
        onMouseMove={handleMouseMove}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        className="relative"
        style={{
          transformStyle: 'preserve-3d',
        }}
      >
        {/* Bottone principale */}
        <motion.button
          ref={buttonRef}
          onClick={toggleDropdown as any}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={`
            relative
            flex
            items-center
            justify-center
            rounded-full
            overflow-hidden
            ${sizeClasses.button}
            focus:outline-none
            hover:bg-gray-50
            dark:hover:bg-gray-800
            transition-colors
            duration-200
            z-10
          `}
          animate={buttonControls}
          style={{
            ...buttonStaticStyle,
            scale: scaleSpring,
            ...customStyles,
          }}
          initial={{ 
            scale: 1,
          }}
          whileHover={{
            scale: 1.02,
            transition: { duration: 0.2 }
          }}
          aria-haspopup="true"
          aria-expanded={isOpen}
          aria-label={`Seleziona lingua: attualmente ${currentLanguage.name}`}
        >
          {/* Background noise e effetti vari */}
          {visualEffects.backgroundNoise && (
            <div 
              className="absolute inset-0 opacity-5 mix-blend-overlay pointer-events-none"
              style={{ 
                backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noise)' opacity='0.1'/%3E%3C/svg%3E")`,
                zIndex: 0,
              }}
            />
          )}
          
          {/* Elementi di sfondo e decorativi */}
          <BorderGlow />
          <HighlightEffect />
          <ReflectionEffect />
          <ShimmerEffect />
          <ParticleSystem />
          
          {/* Glow attorno al bottone */}
          {visualEffects.borderGlow && (
            <motion.div 
              className="absolute inset-0 rounded-full pointer-events-none"
              style={{ 
                boxShadow: buttonGlow,
                zIndex: -1,
              }}
            />
          )}
          
          {/* Contenuto bottone */}
          <div className="flex items-center justify-center relative z-10">
            {/* Sfondo brillante sempre attivo per il pulsante */}
            <motion.div
              className="absolute inset-0 pointer-events-none"
              style={{
                background: shimmerGradient,
                backgroundSize: '200% 100%',
                opacity: 0.4,
                zIndex: 1,
              }}
              animate={{ 
                backgroundPosition: ['0% 0%', '100% 0%', '0% 0%'],
              }}
              transition={{
                duration: 5,
                repeat: Infinity,
                ease: "linear"
              }}
            />
            
            {/* Emoji bandiera con animazione avanzata e always-on */}
            {showFlags && (
              <motion.div 
                className={`${sizeClasses.flag} relative z-10 flex items-center justify-center`}
                variants={flagWaveVariants}
                initial="idle"
                animate="constant" // Sempre attivo per dare più vita alla bandiera
                style={{
                  filter: "drop-shadow(0 2px 4px rgba(0,0,0,0.3))",
                  textShadow: "0 2px 4px rgba(0,0,0,0.3)",
                  transform: "translateZ(0.1em) scale(1.2)",
                  margin: "0 4px 0 2px",
                }}
                aria-hidden="true"
              >
                <div className="relative">
                  <span className="text-3xl" key={`flag-${currentLanguage.code}`}>{currentLanguage.flag}</span>
                  <motion.div 
                    className="absolute inset-0 rounded-full" 
                    style={{
                      background: "radial-gradient(circle at center, rgba(255,255,255,0.8) 0%, transparent 70%)",
                      opacity: 0.6,
                      filter: "blur(1px)",
                      zIndex: -1,
                    }}
                    animate={{ 
                      opacity: [0.4, 0.7, 0.4],
                      scale: [1, 1.05, 1],
                    }}
                    transition={{
                      duration: 3,
                      repeat: Infinity,
                      repeatType: "reverse",
                      ease: "easeInOut"
                    }}
                  />
                </div>
              </motion.div>
            )}
            
            {/* Nome lingua con effetti */}
            {showNames && (
              <motion.span 
                className={`font-medium relative z-10`}
                style={{
                  color: textColor,
                  fontWeight: currentLanguage.fontWeight || 600,
                  fontSize: sizeClasses.text.replace('text-', ''),
                  textShadow: visualEffects.textShadow ? currentLuxuryStyles.textShadow : 'none',
                  transform: 'translateZ(0.05em)',
                }}
              >
                {currentLanguage.name}
                
                {/* Aberrazione cromatica */}
                {visualEffects.textGlow && chromaticAberration > 0.1 && (
                  <>
                    <span 
                      className="absolute left-0 top-0 opacity-50 mix-blend-screen"
                      style={{ 
                        color: '#FF0000',
                        transform: `translateX(${chromaticAberration}px)`, 
                      }}
                      aria-hidden="true"
                    >
                      {currentLanguage.name}
                    </span>
                    <span 
                      className="absolute left-0 top-0 opacity-50 mix-blend-screen"
                      style={{ 
                        color: '#0000FF',
                        transform: `translateX(-${chromaticAberration}px)`, 
                      }}
                      aria-hidden="true"
                    >
                      {currentLanguage.name}
                    </span>
                  </>
                )}
              </motion.span>
            )}
            
            {/* Icona freccia animata */}
            <motion.svg
              className={`ml-1 h-5 w-5`}
              style={{ 
                color: textColor,
                transform: 'translateZ(0.05em)',
                filter: visualEffects.textShadow ? 'drop-shadow(0 1px 1px rgba(0,0,0,0.3))' : 'none',
              }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
              variants={arrowVariants}
              initial="closed"
              animate={isOpen ? "open" : "closed"}
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.707a1 1 0 010-1.414L10 1.586l4.707 4.707a1 1 0 11-1.414 1.414L10 4.414 
                6.707 7.707a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </motion.svg>
          </div>
        </motion.button>
      </motion.div>
      
      {/* Dropdown con animazioni */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            ref={dropdownRef}
            className={dropdownClasses}
            style={{
              width: dropdownWidth,
              maxHeight: dropdownMaxHeight,
              overflowY: 'auto',
              ...customStyles,
              ...glassEffect,
            }}
            variants={dropdownVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="language-options"
          >
            <div 
              className="py-1 divide-y divide-gray-100 dark:divide-gray-800" 
              role="none"
            >
              {languages.map((lang, index) => {
                const isSelected = lang.code === selectedLang;
                const isItemHovered = hoveredLang === lang.code;
                
                // Stili di lusso per questo elemento
                const itemLuxuryStyles = generateLuxuryStyles(lang.luxuryVariant || 'platinum', theme as string);
                
                return (
                  <motion.button
                    key={lang.code}
                    ref={(el) => {
                      if (el) itemsRef.current.set(lang.code, el);
                    }}
                    onClick={(e) => changeLanguage(lang.code, e)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();
                        changeLanguage(lang.code, e);
                      }
                    }}
                    onMouseEnter={() => setHoveredLang(lang.code)}
                    onMouseLeave={() => setHoveredLang(null)}
                    custom={index}
                    variants={itemVariants}
                    whileHover="hover"
                    animate={itemsControls}
                    className={`
                      relative
                      flex
                      items-center
                      w-full
                      px-4
                      py-3
                      transition-colors
                      duration-150
                      focus:outline-none
                      focus:bg-gray-100
                      dark:focus:bg-gray-700
                      overflow-hidden
                      ${sizeClasses.dropdown}
                      ${theme === 'light' ? 'text-gray-900' : 'text-gray-100'}
                      ${isSelected
                        ? themeStyles.itemSelected
                        : isItemHovered
                          ? themeStyles.itemHover
                          : ''
                      }
                    `}
                    role="menuitem"
                    tabIndex={0}
                    aria-current={isSelected ? 'page' : undefined}
                  >
                    {/* Background con gradiente sottile */}
                    {isSelected && (
                      <motion.div
                        className="absolute inset-0 opacity-20 pointer-events-none"
                        style={{
                          background: lang.gradient,
                          zIndex: 0,
                        }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 0.15 }}
                        transition={{ duration: 0.3 }}
                      />
                    )}
                    
                    {/* Effetto bordo attivo */}
                    {isItemHovered && !isSelected && (
                      <motion.div
                        className="absolute left-0 top-0 bottom-0 w-1 pointer-events-none"
                        style={{
                          background: lang.accentColor || itemLuxuryStyles.accentColor,
                          zIndex: 1,
                        }}
                        initial={{ scaleY: 0 }}
                        animate={{ scaleY: 1 }}
                        transition={{ duration: 0.15 }}
                      />
                    )}
                    
                    {/* Contenuto elemento */}
                    <div className="flex items-center w-full relative z-10">
                      {/* Bandiera */}
                      {showFlags && (
                        <div 
                          className={`${sizeClasses.flag} relative z-10 flex items-center justify-center`} 
                          style={{
                            filter: "drop-shadow(0 1px 2px rgba(0,0,0,0.2))",
                            transform: isSelected ? "scale(1.15)" : "scale(1)",
                            transition: "transform 0.3s ease",
                            margin: "0 6px 0 2px"
                          }}
                          aria-hidden="true"
                        >
                          <div className="relative">
                            <span className="text-2xl">{lang.flag}</span>
                            {isSelected && (
                              <motion.div
                                className="absolute inset-0"
                                style={{ 
                                  background: "radial-gradient(circle at center, rgba(255,255,255,0.8) 0%, transparent 70%)",
                                  opacity: 0.6,
                                  filter: "blur(1px)",
                                  zIndex: -1,
                                }}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 0.6 }}
                                transition={{ duration: 0.3 }}
                              />
                            )}
                          </div>
                        </div>
                      )}
                      
                      {/* Nome lingua */}
                      <span 
                        className={`flex-1 relative z-10 ${
                          isSelected 
                            ? 'font-medium'
                            : 'font-normal'
                        }`}
                        style={{
                          fontWeight: isSelected 
                            ? (lang.fontWeight || 600)
                            : (lang.fontWeight || 500),
                        }}
                      >
                        {lang.name}
                      </span>
                      
                      {/* Icona lingua selezionata */}
                      {isSelected && (
                        <motion.svg
                          className="ml-auto h-5 w-5"
                          style={{
                            color: isSelected 
                              ? (theme === 'light' ? '#4F46E5' : '#818CF8')
                              : (theme === 'light' ? '#6B7280' : '#9CA3AF'),
                            filter: 'drop-shadow(0 1px 1px rgba(0,0,0,0.2))',
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                          initial={{ scale: 0, rotate: -45, opacity: 0 }}
                          animate={{ 
                            scale: 1, 
                            rotate: 0, 
                            opacity: 1 
                          }}
                          transition={{
                            type: 'spring',
                            stiffness: 500,
                            damping: 25,
                            delay: index * transitionTimings.staggerChildren,
                          }}
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 01-1.414 1.414L10 4.414l-5.293 5.293a1 1 0 
                            01-1.414-1.414l6-6a1 1 0 011.414 0l6 6z"
                            clipRule="evenodd"
                          />
                        </motion.svg>
                      )}
                    </div>
                    
                    {/* Effetto shine overlay */}
                    {isItemHovered && (
                      <motion.div
                        className="absolute inset-0 pointer-events-none"
                        style={{
                          background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 50%, rgba(255,255,255,0) 100%)',
                          zIndex: 1,
                        }}
                        initial={{ x: '-100%' }}
                        animate={{ x: '100%' }}
                        transition={{ duration: 0.6, ease: 'easeInOut' }}
                      />
                    )}
                  </motion.button>
                );
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      
      {/* Effetto ripple selezione */}
      <SelectionRipple />
    </motion.div>
  );
};

export default LuxuryLanguageSwitcher;