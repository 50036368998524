// src/App.tsx

import React, {
  lazy,
  Suspense,
  FunctionComponent,
  useEffect,
  useMemo,
  useState
} from 'react';

// React Router
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

// Framer Motion
import { motion, AnimatePresence } from 'framer-motion';

// i18n
import { useTranslation } from 'react-i18next';

// Context & Providers
import { ThemeProvider } from './context/ThemeContext';
import { HelmetProvider } from 'react-helmet-async';

// Parallax
import { ParallaxProvider } from 'react-scroll-parallax';

// Altri componenti
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTopButton from './components/ScrollToTopButton';
// import CookieBanner from './components/CookieBanner'; // <-- lo carichiamo lazy

import './styles/globals.css';
import './styles/tailwind.css';

// Rilevazione preferenze (mobile, reduced-motion, ecc.)
import { useMediaQuery } from 'react-responsive';

// Lazy Loading delle Pagine
const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Services = lazy(() => import('./pages/Services2'));
const Contact = lazy(() => import('./pages/Contact'));
const Blog = lazy(() => import('./pages/Blog'));
const ProcessDetail = lazy(() => import('./pages/ProcessDetail'));
const CaseStudies = lazy(() => import('./components/services/CaseStudies'));
const CaseStudyDetail = lazy(() => import('./pages/CaseStudyDetail'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const NotFound = lazy(() => import('./pages/NotFound'));

// CookieBanner lazy (caricato dopo un timeout)
const LazyCookieBanner = lazy(() => import('./components/CookieBanner'));

// Spinner di fallback ottimizzato
const LoadingSpinner: FunctionComponent = () => {
  // Riduciamo dimensioni su mobile
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div className="flex justify-center items-center h-screen">
      <div
        className={`
          animate-spin
          rounded-full
          border-t-2 border-b-2 border-primary
          ${isMobile ? 'h-16 w-16' : 'h-32 w-32'}
        `}
      />
    </div>
  );
};
LoadingSpinner.displayName = 'LoadingSpinner';

// ScrollToTop memoizzato (per ridurre re-render) + displayName
const ScrollToTop = React.memo(() => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
});
ScrollToTop.displayName = 'ScrollToTop';

// Transizione route semplificata su mobile o preferReducedMotion
const AppContent: FunctionComponent = () => {
  const location = useLocation();

  // Rilevazione preferenze per ridurre animazioni su mobile
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const prefersReducedMotion = useMediaQuery({ query: '(prefers-reduced-motion: reduce)' });

  // Se mobile o reduceMotion => fade, altrimenti “y: ±20”
  const initialAnim = useMemo(() => {
    if (isMobile || prefersReducedMotion) {
      return { opacity: 0 };
    }
    return { opacity: 0, y: 20 };
  }, [isMobile, prefersReducedMotion]);

  const animateAnim = useMemo(() => {
    if (isMobile || prefersReducedMotion) {
      return { opacity: 1 };
    }
    return { opacity: 1, y: 0 };
  }, [isMobile, prefersReducedMotion]);

  const exitAnim = useMemo(() => {
    if (isMobile || prefersReducedMotion) {
      return { opacity: 0 };
    }
    return { opacity: 0, y: -20 };
  }, [isMobile, prefersReducedMotion]);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />

      {/* AnimatePresence con transizione semplificata su mobile */}
      <AnimatePresence mode="wait" initial={false}>
        <Routes location={location} key={location.pathname}>
          <Route
            path="*"
            element={
              <motion.div
                initial={initialAnim}
                animate={animateAnim}
                exit={exitAnim}
                transition={{ duration: 0.3 }}
                className="flex-grow"
              >
                <Suspense fallback={<LoadingSpinner />}>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/services/proceso" element={<ProcessDetail />} />
                    <Route path="/case-studies" element={<CaseStudies />} />
                    <Route path="/case-studies/:id" element={<CaseStudyDetail />} />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Suspense>
              </motion.div>
            }
          />
        </Routes>
      </AnimatePresence>

      <Footer />
      <ScrollToTopButton />
    </div>
  );
};
AppContent.displayName = 'AppContent';

const App: FunctionComponent = () => {
  const { t } = useTranslation();

  // Lazy load CookieBanner dopo un timeout
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCookieBanner(true);
    }, 5000); // Carichiamo 5 secondi dopo
    return () => clearTimeout(timer);
  }, []);

  return (
    <HelmetProvider>
      <ThemeProvider>
        <ParallaxProvider>
          <Router>
            <ScrollToTop />

            <div className="min-h-screen w-screen overflow-x-hidden">
              <AppContent />
            </div>

            {/* CookieBanner lazy (se showCookieBanner = true) */}
            {showCookieBanner && (
              <Suspense fallback={null}>
                <LazyCookieBanner />
              </Suspense>
            )}
          </Router>
        </ParallaxProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};
App.displayName = 'App';

export default App;
