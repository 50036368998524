import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  motion,
  useViewportScroll,
  useSpring,
  useTransform,
  useMotionValue,
  useAnimation,
} from 'framer-motion';

const ScrollToTopButton: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false);
  const { scrollYProgress } = useViewportScroll();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const progress = useMotionValue(0);
  const controls = useAnimation();

  // Animazioni per il bottone
  const y = useSpring(
    useTransform(scrollYProgress, [0, 0.2], [100, 0]),
    { stiffness: 300, damping: 30 }
  );
  const opacity = useTransform(scrollYProgress, [0, 0.1], [0, 1]);
  const gradientRotate = useTransform(progress, [0, 1], [0, 360]);

  const scrollToTop = useCallback(() => {
    controls.start({
      rotate: [0, 360],
      scale: [1, 0.8, 1.2, 1],
      transition: { duration: 0.5 },
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [controls]);

  useEffect(() => {
    // Aggiornamento della progress bar
    const updateProgress = () => {
      const scrollPercentage =
        window.scrollY /
        (document.documentElement.scrollHeight - window.innerHeight);
      progress.set(scrollPercentage);
    };
    window.addEventListener('scroll', updateProgress);
    updateProgress(); // Aggiorna immediatamente al montaggio

    return () => {
      window.removeEventListener('scroll', updateProgress);
    };
  }, [progress]);

  useEffect(() => {
    // Animazione delle particelle
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (!canvas || !ctx) return;

    let animationFrameId: number;
    let particles: Array<{
      x: number;
      y: number;
      radius: number;
      color: string;
      velocity: { x: number; y: number };
    }> = [];

    const createParticles = () => {
      particles = [];
      for (let i = 0; i < 100; i++) {
        particles.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          radius: Math.random() * 3 + 1,
          color: `hsl(${Math.random() * 360}, 70%, 60%)`,
          velocity: {
            x: (Math.random() - 0.5) * 1.5,
            y: (Math.random() - 0.5) * 1.5,
          },
        });
      }
    };

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      particles.forEach((particle) => {
        particle.x += particle.velocity.x;
        particle.y += particle.velocity.y;

        // Rimbalzo ai bordi
        if (particle.x < 0 || particle.x > canvas.width)
          particle.velocity.x *= -1;
        if (particle.y < 0 || particle.y > canvas.height)
          particle.velocity.y *= -1;

        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
        ctx.fillStyle = particle.color;
        ctx.fill();
      });
      animationFrameId = requestAnimationFrame(animate);
    };

    const handleResize = () => {
      if (buttonRef.current) {
        canvas.width = buttonRef.current.offsetWidth;
        canvas.height = buttonRef.current.offsetHeight;
        createParticles();
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    animate();

    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <motion.button
      ref={buttonRef}
      style={{
        position: 'fixed',
        bottom: '2rem',
        right: '2rem',
        width: '60px',
        height: '60px',
        borderRadius: '50%',
        backgroundColor: 'rgba(255, 215, 0, 0.2)',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
        overflow: 'hidden',
        y,
        opacity,
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
        zIndex: 1000, // Assicurati che sia sopra altri elementi
      }}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      onClick={scrollToTop}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      aria-label="Scroll to top"
      animate={controls}
    >
      <motion.div
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          background: 'linear-gradient(45deg, #FFD700, #FFA500)',
          rotate: gradientRotate,
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
      <canvas
        ref={canvasRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          opacity: isHovered ? 1 : 0,
          transition: 'opacity 0.3s ease',
          mixBlendMode: 'overlay',
        }}
      />
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{
          width: '24px',
          height: '24px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1,
        }}
        animate={{
          y: isHovered ? -3 : 0,
          rotate: isHovered ? 360 : 0,
          scale: isHovered ? 1.2 : 1,
        }}
        transition={{ duration: 0.3 }}
      >
        <path d="M12 19V5M5 12l7-7 7 7" />
      </motion.svg>
    </motion.button>
  );
};

export default React.memo(ScrollToTopButton);
