// /src/components/Header.tsx
// REVOLUTIONARY HEADER COMPONENT
// A daring masterpiece that redefines what's possible in web design
// Bold, dramatic, and utterly distinctive while maintaining professional credibility

import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback
} from 'react';
import {
  Link,
  useLocation
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  motion,
  AnimatePresence,
  useViewportScroll,
  useTransform,
  useMotionValue,
  useReducedMotion,
  Variants,
  useSpring,
  transform,
  MotionValue
} from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from '../context/ThemeContext';
import LanguageSwitcher from './LanguageSwitcher';

/**
 * REVOLUTIONARY HEADER COMPONENT
 * 
 * A bold design statement that shatters conventional web design expectations
 * while maintaining the professional credibility essential for financial services.
 * 
 * This header creates a dramatic, unforgettable first impression that communicates
 * innovation, precision, and trustworthiness through advanced visual techniques
 * and cutting-edge animation.
 */
const Header: React.FC = () => {
  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  // ━━━ CORE STATE - The Foundation of Our Visual Revolution ━━━━━━━━━━━━━
  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hoverItem, setHoverItem] = useState<string | null>(null);
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down' | null>(null);
  const [lastScrollY, setLastScrollY] = useState<number>(0);
  const [logoHover, setLogoHover] = useState<boolean>(false);
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);
  const [cursorPosition, setCursorPosition] = useState<{x: number, y: number}>({x: 0, y: 0});
  const [titleHover, setTitleHover] = useState<boolean>(false);
  const [mouseEnterTime, setMouseEnterTime] = useState<number>(0);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [scrollTimeout, setScrollTimeout] = useState<NodeJS.Timeout | null>(null);

  const { theme, toggleTheme } = useTheme();
  const location = useLocation();

  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  // ━━━ DEVICE DETECTION - Responsive Masterpiece Across All Screens ━━━━━━
  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isLargeScreen = useMediaQuery({ minWidth: 1440 });
  const is4K = useMediaQuery({ minWidth: 2560 });
  const userPrefersReducedMotion = useReducedMotion();

  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  // ━━━ ELEMENT REFERENCES - For Precise DOM Orchestration ━━━━━━━━━━━━━━━
  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  
  const headerRef = useRef<HTMLElement>(null);
  const logoRef = useRef<HTMLDivElement>(null);
  const navRef = useRef<HTMLDivElement>(null);
  const toggleButtonRef = useRef<HTMLButtonElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  
  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  // ━━━ ADVANCED SCROLL MANAGEMENT - Dramatic Visual Transformations ━━━━━━
  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  
  const { scrollY } = useViewportScroll();
  const headerHeight = useMotionValue<number>(80);
  const headerOpacity = useMotionValue<number>(1);
  const headerScale = useMotionValue<number>(1);
  const logoRotation = useMotionValue<number>(0);
  const logoScale = useMotionValue<number>(1);
  const navOpacity = useMotionValue<number>(1);
  const parallaxOffset = useMotionValue<number>(0);
  
  // Define inverse parallax effect at top level to avoid hook issues
  const inverseParallaxOffset = useTransform(parallaxOffset, (value) => -value);

  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  // ━━━ REVOLUTIONARY GRADIENTS - Bold Visual Statement ━━━━━━━━━━━━━━━━━━
  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  
  /**
   * DRAMATIC LIGHT THEME
   * 
   * A bold, striking gradient that commands attention while maintaining professionalism
   * Deep blues and teals create a sense of depth and trustworthiness
   * Contrasting with bright accent colors for visual impact
   * Sophisticated patterns suggest precision and technological expertise
   */
  const dramaticLightGradient = `
    bg-gradient-to-br
    from-blue-50
    via-white
    to-cyan-50
    before:content-['']
    before:absolute
    before:inset-0
    before:bg-[radial-gradient(circle_at_30%_20%,rgba(6,182,212,0.15),transparent_50%),radial-gradient(circle_at_70%_60%,rgba(59,130,246,0.1),transparent_50%)]
    before:z-[-1]
    after:content-['']
    after:absolute
    after:inset-0
    after:bg-[url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%230ea5e9' fill-opacity='0.02' fill-rule='evenodd'/%3E%3C/svg%3E")]
    after:z-[-1]
  `;

  /**
   * PRESERVED DARK THEME
   * 
   * Keeping the client's beloved dark theme with its cosmic elegance
   * Deep space-inspired gradients creating a sense of infinite possibility
   */
  const dramaticDarkGradient = `
    [background-image:linear-gradient(to_bottom_left,#000000_0%,#0b1c2c_40%,#420000_100%)]
    before:content-['']
    before:absolute
    before:inset-0
    before:bg-[conic-gradient(var(--tw-gradient-stops))]
    before:from-transparent
    before:via-red-900/30
    before:to-transparent
    before:pointer-events-none
    before:opacity-20
    before:z-[-1]
    after:content-['']
    after:absolute
    after:inset-0
    after:bg-[conic-gradient(var(--tw-gradient-stops))]
    after:from-transparent
    after:via-blue-900/30
    after:to-transparent
    after:pointer-events-none
    after:opacity-10
    after:z-[-1]
  `;

  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  // ━━━ DRAMATIC VISUAL EFFECTS - Creating Bold Dimension ━━━━━━━━━━━━━━━━
  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  
  /**
   * SOPHISTICATED SHADOW SYSTEM
   * 
   * Multi-layered shadows creating dramatic depth and visual hierarchy
   * Response to scroll position creates dynamic visual interest
   */
  const headerShadow = useTransform(
    scrollY,
    [0, 50, 100],
    [
      '0px 0px 0px rgba(0,0,0,0)',
      theme === 'light'
        ? '0px 10px 30px rgba(14, 165, 233, 0.07), 0px 4px 15px rgba(0, 0, 0, 0.035)'
        : '0px 5px 20px rgba(0,0,0,0.4)',
      theme === 'light'
        ? '0px 15px 35px rgba(14, 165, 233, 0.12), 0px 8px 20px rgba(0, 0, 0, 0.05)'
        : '0px 8px 25px rgba(0,0,0,0.5)'
    ]
  );

  /**
   * ADVANCED BACKDROP EFFECTS
   * 
   * Creates sophisticated glass-like effects that evolve as the user scrolls
   * Adjusts dramatically based on scroll position for visual dynamism
   */
  const backdropBlur = useTransform(
    scrollY,
    [0, 100],
    [0, theme === 'light' ? 8 : 3]
  );

  const backdropFilter = useTransform(
    backdropBlur,
    (value) => `blur(${value}px)`
  );

  /**
   * DYNAMIC BACKGROUND TRANSFORMATIONS
   * 
   * Creates dramatic shifts in background properties as user scrolls
   * Combines multiple transformations for sophisticated visual effect
   */
  const backgroundOpacity = useTransform(
    scrollY,
    [0, 100],
    [0, theme === 'light' ? 0.92 : 0.85]
  );

  const backgroundSaturation = useTransform(
    scrollY,
    [0, 100],
    [100, theme === 'light' ? 108 : 95]
  );

  const backgroundBrightness = useTransform(
    scrollY,
    [0, 100],
    [100, theme === 'light' ? 102 : 97]
  );

  const backgroundFilter = useTransform(
    [backgroundSaturation, backgroundBrightness],
    ([saturation, brightness]) => `saturate(${saturation}%) brightness(${brightness}%)`
  );

  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  // ━━━ SCROLL EFFECTS ORCHESTRATION - Dynamic Visual Symphony ━━━━━━━━━━━
  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      
      // Detect and update scroll direction for directional effects
      const newScrollDirection = scrollPosition > lastScrollY ? 'down' : 'up';
      if (newScrollDirection !== scrollDirection) {
        setScrollDirection(newScrollDirection);
      }
      setLastScrollY(scrollPosition);
      
      // Detect scroll status for conditional styling
      setHasScrolled(scrollPosition > 20);
      
      // Set scrolling state with debounce for transition effects
      setIsScrolling(true);
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
      
      const timeout = setTimeout(() => {
        setIsScrolling(false);
      }, 150);
      
      setScrollTimeout(timeout);
      
      // Parallax effect for depth
      parallaxOffset.set(scrollPosition * 0.05);
      
      // Sophisticated header transformations with advanced physics and easing
      if (scrollPosition < 50) {
        // Full dramatic presence at viewport top
        headerHeight.set(80);
        headerOpacity.set(1);
        headerScale.set(1);
        logoScale.set(1);
        navOpacity.set(1);
      } else if (scrollPosition < 300) {
        // Graceful transition with cubic easing
        const factor = (scrollPosition - 50) / 250;
        const easeOutCubic = 1 - Math.pow(1 - factor, 3);
        
        headerHeight.set(80 - (easeOutCubic * 10));
        headerOpacity.set(1);
        headerScale.set(1 - (easeOutCubic * 0.02));
        logoScale.set(1 - (easeOutCubic * 0.05));
        navOpacity.set(1 - (easeOutCubic * 0.1));
      } else {
        // Compact sophistication when fully scrolled
        headerHeight.set(70);
        headerOpacity.set(0.97);
        headerScale.set(0.98);
        logoScale.set(0.95);
        navOpacity.set(0.9);
      }
    };

    // Add passive event listener for optimal performance
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
    };
  }, [headerHeight, headerOpacity, headerScale, lastScrollY, scrollDirection, logoScale, navOpacity, parallaxOffset, scrollTimeout]);

  // Track cursor position for advanced hover effects
  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      setCursorPosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  // ━━━ NAVIGATION CONFIGURATION - Professional Pathways ━━━━━━━━━━━━━━━━━
  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  
  const navItems = useMemo(() => {
    return [
      { name: t('header.home'), path: '/' },
      { name: t('header.about'), path: '/about' },
      { name: t('header.services'), path: '/services' },
      { name: t('header.contact'), path: '/contact' },
    ];
  }, [t]);

  // Reset mobile menu when navigation changes
  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  // ━━━ DRAMATIC ANIMATION VARIANTS - Orchestrating Visual Drama ━━━━━━━━━━
  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  
  /**
   * MOBILE MENU ANIMATION
   * 
   * Creates a dramatic reveal with sophisticated timing and phasing
   * Each element choreographed for maximum impact
   */
  const menuVariants: Variants = {
    open: {
      clipPath: "inset(0% 0% 0% 0% round 0px)",
      transition: userPrefersReducedMotion
        ? { duration: 0.4 }
        : {
            type: "spring",
            bounce: 0,
            duration: 0.7,
            delayChildren: 0.1,
            staggerChildren: 0.07
          }
    },
    closed: {
      clipPath: "inset(10% 50% 90% 50% round 10px)",
      transition: userPrefersReducedMotion
        ? { duration: 0.4 }
        : {
            type: "spring",
            bounce: 0,
            duration: 0.5,
            delay: 0.1,
            staggerChildren: 0.05,
            staggerDirection: -1
          }
    }
  };

  /**
   * MENU ITEM ANIMATION
   * 
   * Creates bold entrance and exit animations for menu items
   * Uses advanced easing for dramatic, professional movement
   */
  const menuItemVariants: Variants = {
    open: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: { 
        duration: 0.6, 
        ease: [0.22, 1, 0.36, 1],
        scale: { 
          type: "spring", 
          stiffness: 400, 
          damping: 25
        }
      }
    },
    closed: {
      opacity: 0,
      y: -20,
      scale: 0.95,
      transition: { 
        duration: 0.3, 
        ease: [0.22, 1, 0.36, 1]
      }
    }
  };

  /**
   * THEME TOGGLE ANIMATION
   * 
   * Creates dramatic transition between light and dark themes
   * Sophisticated rotation and scaling for visual impact
   */
  const themeToggleVariants: Variants = {
    light: {
      rotate: userPrefersReducedMotion ? 0 : [0, 180, 360],
      scale: userPrefersReducedMotion ? 1 : [1, 1.15, 1],
      transition: {
        duration: 1.2,
        times: [0, 0.5, 1],
        ease: [0.16, 1, 0.3, 1]
      }
    },
    dark: {
      rotate: userPrefersReducedMotion ? 0 : [0, 180, 360],
      scale: userPrefersReducedMotion ? 1 : [1, 1.15, 1],
      transition: {
        duration: 1.2,
        times: [0, 0.5, 1],
        ease: [0.16, 1, 0.3, 1]
      }
    }
  };

  /**
   * NAVIGATION HOVER ANIMATION
   * 
   * Creates bold hover effects for navigation items
   * Balances visual impact with professional restraint
   */
  const navItemVariants: Variants = {
    initial: {
      y: 0,
      scale: 1
    },
    hover: userPrefersReducedMotion ? {} : {
      y: -3,
      scale: 1.05,
      transition: {
        duration: 0.3,
        ease: [0.22, 1, 0.36, 1]
      }
    },
    tap: userPrefersReducedMotion ? {} : {
      y: 1,
      scale: 0.95,
      transition: {
        duration: 0.15,
        ease: [0.22, 1, 0.36, 1]
      }
    }
  };

  /**
   * UNDERLINE ANIMATION
   * 
   * Creates dramatic underline effects for navigation items
   * Uses sophisticated timing and easing for maximum impact
   */
  const underlineVariants: Variants = {
    hidden: {
      scaleX: 0,
      opacity: 0,
    },
    visible: {
      scaleX: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: [0.22, 1, 0.36, 1]
      }
    },
    hover: {
      scaleX: 1,
      opacity: 0.8,
      transition: {
        duration: 0.4,
        ease: [0.22, 1, 0.36, 1]
      }
    }
  };

  /**
   * LOGO ANIMATION
   * 
   * Creates dramatic hover effects for the logo area
   * Bold visual impact with professional restraint
   */
  const logoVariants: Variants = {
    initial: {
      filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1))",
      scale: 1
    },
    hover: userPrefersReducedMotion ? {} : {
      filter: theme === 'light'
        ? "drop-shadow(0px 4px 8px rgba(14, 165, 233, 0.2))"
        : "drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25))",
      scale: 1.03,
      transition: {
        duration: 0.5,
        ease: [0.22, 1, 0.36, 1]
      }
    }
  };

  /**
   * TITLE ANIMATION
   * 
   * Creates bold typography animation
   * Uses dramatic transformations for professional impact
   */
  const titleVariants: Variants = {
    initial: {
      scale: 1,
      textShadow: theme === 'light'
        ? '0 1px 2px rgba(0,0,0,0.1)'
        : '0 1px 3px rgba(0,0,0,0.3)'
    },
    hover: userPrefersReducedMotion ? {} : {
      scale: 1.02,
      textShadow: theme === 'light'
        ? '0 2px 5px rgba(14, 165, 233, 0.2)'
        : '0 2px 7px rgba(0,0,0,0.4)',
      transition: {
        duration: 0.4,
        ease: [0.22, 1, 0.36, 1]
      }
    }
  };

  /**
   * HIGHLIGHT ANIMATION
   * 
   * Creates dramatic shimmer effects for accent elements
   * Uses advanced animation for bold visual impact
   */
  const highlightVariants: Variants = {
    initial: {
      opacity: 0
    },
    animate: {
      opacity: [0, 0.7, 0],
      transition: {
        duration: 5,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "reverse"
      }
    },
    hover: {
      opacity: 0.9,
      transition: {
        duration: 0.3
      }
    }
  };

  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  // ━━━ INTERACTION HANDLERS - Orchestrating User Experience ━━━━━━━━━━━━━
  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  
  const handleItemHover = useCallback((path: string | null) => {
    if (!userPrefersReducedMotion) {
      setHoverItem(path);
    }
  }, [userPrefersReducedMotion]);

  const handleLogoHover = useCallback((isHovering: boolean) => {
    if (!userPrefersReducedMotion) {
      setLogoHover(isHovering);
      if (isHovering) {
        setMouseEnterTime(Date.now());
      }
    }
  }, [userPrefersReducedMotion]);

  const handleTitleHover = useCallback((isHovering: boolean) => {
    if (!userPrefersReducedMotion) {
      setTitleHover(isHovering);
    }
  }, [userPrefersReducedMotion]);

  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  // ━━━ MOTION SETTINGS - Fine-tuning Our Visual Masterpiece ━━━━━━━━━━━━━
  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  
  // Dynamic motion settings based on device and user preferences
  const whileHoverDesktop = useMemo(() => {
    if (isMobile || userPrefersReducedMotion) {
      return {}; // No animation for mobile or reduced motion
    }
    return { 
      scale: 1.08, 
      transition: { 
        duration: 0.4, 
        ease: [0.19, 1, 0.22, 1] 
      } 
    };
  }, [isMobile, userPrefersReducedMotion]);

  const whileTapDesktop = useMemo(() => {
    if (isMobile || userPrefersReducedMotion) {
      return {};
    }
    return { 
      scale: 0.95, 
      transition: { 
        duration: 0.2, 
        ease: [0.19, 1, 0.22, 1] 
      } 
    };
  }, [isMobile, userPrefersReducedMotion]);

  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  // ━━━ REVOLUTIONARY COLOR SYSTEM - Bold Professional Palette ━━━━━━━━━━━
  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  
  /**
   * BOLD LIGHT THEME COLORS
   * 
   * Striking color palette that commands attention while maintaining professionalism
   * Deep blues and cyan accents create a sense of trust and technological sophistication
   */
  const boldLightColors = {
    primary: 'text-sky-600',
    secondary: 'text-cyan-500',
    accent: 'text-blue-500',
    accentHover: 'text-blue-700',
    navText: 'text-gray-800',
    navTextHover: 'text-sky-700',
    navActive: 'text-sky-600',
    background: 'bg-white',
    backgroundAccent: 'bg-sky-50',
    backgroundHover: 'bg-gradient-to-br from-sky-50 to-blue-50',
    border: 'border-sky-100',
    icon: 'text-sky-600',
    buttonBg: 'bg-gradient-to-br from-sky-50 to-white',
    buttonBgHover: 'bg-gradient-to-br from-sky-100/80 to-sky-50/80',
    buttonBgActive: 'bg-sky-100',
    line: 'bg-gradient-to-r from-sky-400 via-blue-500 to-sky-400',
    shadow: 'shadow-sky-900/5',
    shadowHover: 'shadow-sky-900/10',
    decoration: 'bg-blue-500/10',
  };

  /**
   * PRESERVED DARK THEME COLORS
   * 
   * The client's favorite dark color scheme preserved and enhanced
   * Dramatic nighttime aesthetic with warm amber accents
   */
  const darkColors = {
    primary: 'text-amber-300',
    secondary: 'text-amber-400',
    accent: 'text-amber-300',
    accentHover: 'text-amber-200',
    navText: 'text-gray-100',
    navTextHover: 'text-amber-200',
    navActive: 'text-amber-300',
    background: 'bg-gray-900',
    backgroundAccent: 'bg-gray-800',
    backgroundHover: 'bg-gradient-to-br from-gray-800 to-gray-900',
    border: 'border-gray-700',
    icon: 'text-amber-300',
    buttonBg: 'bg-gray-800/80',
    buttonBgHover: 'bg-gray-700/80',
    buttonBgActive: 'bg-gray-700',
    line: 'bg-gradient-to-r from-amber-400 via-amber-300 to-amber-400',
    shadow: 'shadow-black/40',
    shadowHover: 'shadow-black/50',
    decoration: 'bg-amber-700/10',
  };

  // Active color set based on current theme
  const colors = theme === 'light' ? boldLightColors : darkColors;

  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  // ━━━ ADVANCED SPRING PHYSICS - Creating Dramatic Motion Poetry ━━━━━━━━━
  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  
  /**
   * SOPHISTICATED SPRING CONFIGURATION
   * 
   * Precisely tuned physics parameters for dramatic, professional motion
   * Creates a sense of weight and sophistication in all animations
   */
  const springConfig = { 
    stiffness: 100, 
    damping: 30, 
    mass: 1,
    restDelta: 0.001
  };
  
  // Applied spring physics to motion values
  const headerHeightSpring = useSpring(headerHeight, springConfig);
  const headerOpacitySpring = useSpring(headerOpacity, springConfig);
  const headerShadowSpring = useSpring(headerShadow, springConfig);
  const backdropFilterSpring = useSpring(backdropFilter, springConfig);
  const logoScaleSpring = useSpring(logoScale, springConfig);
  const backgroundFilterSpring = useSpring(backgroundFilter, springConfig);
  const parallaxOffsetSpring = useSpring(parallaxOffset, { 
    stiffness: 50, 
    damping: 20 
  });
  const inverseParallaxOffsetSpring = useSpring(inverseParallaxOffset, {
    stiffness: 50,
    damping: 20
  });

  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  // ━━━ RENDER - Our Dramatic Masterpiece Comes to Life ━━━━━━━━━━━━━━━━━━
  // ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  
  return (
    <motion.header
      ref={headerRef}
      className={`
        fixed
        w-full
        z-50
        flex
        items-center
        transition-all
        duration-500
        ${
          theme === 'light'
            ? dramaticLightGradient
            : dramaticDarkGradient
        }
      `}
      style={{
        height: headerHeightSpring,
        boxShadow: headerShadowSpring,
        backdropFilter: backdropFilterSpring,
        opacity: headerOpacitySpring,
        filter: backgroundFilterSpring,
      }}
      initial={{ y: -100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.8, ease: [0.16, 1, 0.3, 1] }}
    >
      {/* ━━━ DRAMATIC BACKDROP OVERLAY - Creating Bold Visual Depth ━━━ */}
      <motion.div 
        className={`
          absolute 
          inset-0 
          pointer-events-none 
          z-0
          ${theme === 'light' ? 'bg-gradient-to-r from-sky-50/40 via-white/90 to-blue-50/40' : ''}
          overflow-hidden
        `}
        style={{
          opacity: backgroundOpacity
        }}
      >
        {/* Dynamic Background Elements */}
        {theme === 'light' && (
          <>
            {/* Dynamic Backdrop Elements - Creating Bold Visual Interest */}
            <motion.div
              className="absolute -top-32 -right-32 w-96 h-96 rounded-full bg-gradient-to-br from-sky-100/30 via-white/0 to-transparent opacity-40 blur-2xl"
              animate={{
                scale: [1, 1.05, 1],
                opacity: [0.4, 0.5, 0.4],
              }}
              transition={{
                duration: 8,
                ease: "easeInOut",
                repeat: Infinity,
                repeatType: "reverse"
              }}
            />
            <motion.div
              className="absolute -bottom-32 -left-32 w-96 h-96 rounded-full bg-gradient-to-br from-blue-100/20 via-white/0 to-transparent opacity-30 blur-2xl"
              animate={{
                scale: [1, 1.1, 1],
                opacity: [0.3, 0.4, 0.3],
              }}
              transition={{
                duration: 10,
                ease: "easeInOut",
                repeat: Infinity,
                repeatType: "reverse",
                delay: 1
              }}
            />
          </>
        )}
      </motion.div>

      {/* ━━━ DRAMATIC GEOMETRIC ACCENTS - Bold Visual Elements ━━━ */}
      {theme === 'light' && (
        <>
          <motion.div 
            className="absolute -right-10 top-0 h-40 w-40 rounded-full bg-gradient-to-br from-sky-400/5 to-blue-500/5 blur-xl z-0 opacity-70"
            style={{ 
              y: parallaxOffsetSpring
            }}
          />
          <motion.div 
            className="absolute -left-10 bottom-0 h-32 w-32 rounded-full bg-gradient-to-tr from-blue-400/5 to-sky-500/5 blur-xl z-0 opacity-60"
            style={{ 
              y: inverseParallaxOffsetSpring
            }}
          />
        </>
      )}

      {/* ━━━ MAIN CONTENT CONTAINER - Housing Our Visual Drama ━━━ */}
      <div
        className={`
          relative
          z-10
          container
          mx-auto
          px-4
          md:px-6
          lg:px-8
          xl:px-10
          h-full
          flex
          justify-between
          items-center
        `}
      >
        {/* ━━━ LOGO & BRAND IDENTITY - The Bold Visual Anchor ━━━ */}
        <motion.div
          ref={logoRef}
          className="flex items-center group relative"
          variants={logoVariants}
          initial="initial"
          animate={logoHover ? "hover" : "initial"}
          whileHover="hover"
          whileTap={whileTapDesktop}
          onHoverStart={() => handleLogoHover(true)}
          onHoverEnd={() => handleLogoHover(false)}
        >
          {/* Dramatic Logo Glow Effect */}
          <motion.div
            className={`
              absolute
              -inset-3
              rounded-full
              opacity-0
              group-hover:opacity-60
              ${theme === 'light' ? 'bg-sky-200/30' : 'bg-amber-700/20'}
              blur-xl
              transition-opacity
              duration-700
              -z-10
            `}
            variants={highlightVariants}
            initial="initial"
            animate={logoHover ? "hover" : "animate"}
          />

          {/* Bold Logo with Dynamic Effects */}
          <motion.img
            src={theme === 'light' ? '/images/logo.png' : '/images/logo30.png'}
            alt="Logo Contable Tenerife"
            className="h-10 w-auto relative z-10"
            style={{
              scale: logoScaleSpring,
              filter: theme === 'light' 
                ? 'drop-shadow(0 2px 4px rgba(14, 165, 233, 0.15))' 
                : 'drop-shadow(0 2px 6px rgba(0,0,0,0.25))'
            }}
            animate={{
              rotate: [0, 0.5, 0, -0.5, 0],
            }}
            transition={{
              duration: 6,
              ease: "easeInOut",
              repeat: Infinity,
              repeatType: "reverse"
            }}
          />

          {/* Title with Bold Typography Effects */}
          <motion.div
            ref={titleRef}
            className="flex flex-col ml-3 relative"
            variants={titleVariants}
            initial="initial"
            animate={titleHover ? "hover" : "initial"}
            onHoverStart={() => handleTitleHover(true)}
            onHoverEnd={() => handleTitleHover(false)}
          >
            {/* Main Title with Dynamic Text Effects */}
            <motion.span
              className={`
                text-3xl
                font-extrabold
                tracking-tight
                ${
                  theme === 'light'
                    ? 'text-gray-900'
                    : 'text-white'
                }
              `}
            >
              Contable{' '}
              <span
                className={`
                  relative
                  ${theme === 'light' ? colors.primary : colors.accent}
                `}
              >
                {/* Dramatic Shimmer Effect */}
                <motion.span
                  className={`
                    absolute
                    inset-0
                    rounded-lg
                    -z-10
                    ${theme === 'light' ? 'bg-gradient-to-r from-sky-200/0 via-sky-200/70 to-sky-200/0' : 'bg-gradient-to-r from-amber-700/0 via-amber-700/40 to-amber-700/0'}
                    blur-sm
                  `}
                  animate={{
                    x: ['-100%', '100%'],
                  }}
                  transition={{
                    duration: 3,
                    ease: "easeInOut",
                    repeat: Infinity,
                    repeatDelay: 5
                  }}
                />
                
                Tenerife
              </span>
            </motion.span>
            
            {/* Professional Subtitle with Bold Animation */}
            <motion.span
              className={`
                text-xs
                font-medium
                tracking-wide
                ${
                  theme === 'light'
                    ? 'text-gray-500'
                    : 'text-gray-300'
                }
              `}
              initial={{ opacity: 0, y: -5 }}
              animate={{ opacity: 0.9, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              {t('header.professionalAccounting')}
            </motion.span>
          </motion.div>
        </motion.div>

        {/* ━━━ DESKTOP NAVIGATION - Bold Pathways Through Our Experience ━━━ */}
        <motion.nav
          ref={navRef}
          className={`
            hidden
            md:flex
            items-center
            space-x-1
            lg:space-x-2
            xl:space-x-3
            relative
            z-[9999]
          `}
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.1 }}
          style={{ opacity: navOpacity }}
        >
          {/* Bold Navigation Links */}
          {navItems.map((item, index) => (
            <motion.div
              key={item.path}
              className="relative px-3 py-1"
              onHoverStart={() => handleItemHover(item.path)}
              onHoverEnd={() => handleItemHover(null)}
              variants={navItemVariants}
              initial="initial"
              whileHover="hover"
              whileTap="tap"
            >
              {/* Dramatic Background Highlight Effect */}
              <motion.div
                className={`
                  absolute
                  inset-0
                  rounded-md
                  -z-10
                  opacity-0
                  ${
                    theme === 'light'
                      ? colors.backgroundAccent
                      : colors.backgroundAccent
                  }
                `}
                initial={{ opacity: 0 }}
                animate={{ 
                  opacity: location.pathname === item.path 
                    ? 0.9 
                    : hoverItem === item.path 
                      ? 0.6 
                      : 0 
                }}
                transition={{ duration: 0.3 }}
              />

              {/* Bold Navigation Link Styling */}
              <Link
                to={item.path}
                className={`
                  relative
                  block
                  py-2
                  px-3
                  text-base
                  font-medium
                  transition-colors
                  duration-300
                  rounded-md
                  ${
                    theme === 'light'
                      ? location.pathname === item.path
                        ? colors.navActive
                        : `${colors.navText} hover:${colors.navTextHover}`
                      : location.pathname === item.path
                        ? colors.navActive
                        : `${colors.navText} hover:${colors.navTextHover}`
                  }
                `}
                aria-current={location.pathname === item.path ? 'page' : undefined}
              >
                {/* Text with conditional hover effect */}
                <motion.span
                  animate={{
                    y: hoverItem === item.path ? -1 : 0
                  }}
                  transition={{ duration: 0.2 }}
                >
                  {item.name}
                </motion.span>
                
                {/* Bold Gradient Underline Effect */}
                <motion.span
                  className={`
                    absolute
                    bottom-1
                    left-0
                    right-0
                    mx-auto
                    w-4/5
                    h-0.5
                    rounded-full
                    ${colors.line}
                    origin-center
                  `}
                  variants={underlineVariants}
                  initial="hidden"
                  animate={
                    location.pathname === item.path 
                      ? "visible" 
                      : hoverItem === item.path 
                        ? "hover" 
                        : "hidden"
                  }
                />
              </Link>
            </motion.div>
          ))}

          {/* ━━━ LANGUAGE SWITCHER - Global Accessibility ━━━ */}
          {/* FIXED: Removed motion.div wrapper for LanguageSwitcher */}
          <div className="relative mx-2 lg:mx-3" style={{ zIndex: 9999 }}>
            <LanguageSwitcher />
          </div>

          {/* ━━━ THEME TOGGLE - Dramatic Day/Night Transformation ━━━ */}
          <motion.button
            ref={toggleButtonRef}
            onClick={toggleTheme}
            className={`
              relative
              ml-3
              lg:ml-4
              p-3
              rounded-full
              overflow-hidden
              ${colors.buttonBg}
              ${colors.icon}
              hover:${colors.buttonBgHover}
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-offset-white
              dark:focus:ring-offset-gray-900
              ${
                theme === 'light'
                  ? 'focus:ring-sky-400'
                  : 'focus:ring-amber-400'
              }
              transition-all
              duration-300
              shadow-lg
              ${colors.shadow}
              hover:${colors.shadowHover}
            `}
            aria-label={t('header.toggleTheme') || 'Change theme'}
            whileHover={whileHoverDesktop}
            whileTap={whileTapDesktop}
            variants={themeToggleVariants}
            animate={theme === 'light' ? 'light' : 'dark'}
          >
            {/* Dramatic Shimmer Animation */}
            <motion.div
              className={`
                absolute
                inset-0
                ${
                  theme === 'light'
                    ? 'bg-gradient-to-r from-sky-200/0 via-sky-200/50 to-sky-200/0'
                    : 'bg-gradient-to-r from-amber-700/0 via-amber-700/30 to-amber-700/0'
                }
              `}
              animate={{
                x: ['-200%', '200%'],
              }}
              transition={{
                duration: 3,
                ease: "easeInOut",
                repeat: Infinity,
                repeatDelay: 3
              }}
            />

            {/* Theme Icon with Dramatic Transition */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              key={theme}
              transition={{ duration: 0.5 }}
              className="relative z-10"
            >
              {theme === 'light' ? (
                // Bold Sun Icon Animation
                <motion.svg
                  xmlns="http://www.w3.org/2000/svg"
                  key="sun"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  animate={{
                    rotate: [0, 180, 360],
                  }}
                  transition={{
                    duration: 40,
                    ease: "linear",
                    repeat: Infinity
                  }}
                >
                  <circle cx="12" cy="12" r="5" />
                  <motion.g
                    animate={{
                      scale: [1, 1.1, 1],
                      opacity: [0.8, 1, 0.8]
                    }}
                    transition={{
                      duration: 3,
                      ease: "easeInOut",
                      repeat: Infinity,
                      repeatType: "reverse"
                    }}
                  >
                    <line x1="12" y1="1" x2="12" y2="3" />
                    <line x1="12" y1="21" x2="12" y2="23" />
                    <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
                    <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
                    <line x1="1" y1="12" x2="3" y2="12" />
                    <line x1="21" y1="12" x2="23" y2="12" />
                    <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
                    <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
                  </motion.g>
                </motion.svg>
              ) : (
                // Dramatic Moon Icon Animation
                <motion.svg
                  xmlns="http://www.w3.org/2000/svg"
                  key="moon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M21 12.79A9 9 0 1 1 11.21 3a7 7 0 0 0 9.79 9.79z" />
                  <motion.g
                    animate={{
                      opacity: [0, 1, 0],
                    }}
                    transition={{
                      duration: 3,
                      ease: "easeInOut",
                      repeat: Infinity,
                      repeatType: "reverse",
                      delay: 1,
                      repeatDelay: 2
                    }}
                  >
                    <circle cx="7.5" cy="5.5" r="0.5" fill="currentColor" />
                    <circle cx="19" cy="10" r="0.5" fill="currentColor" />
                    <circle cx="17" cy="6" r="0.5" fill="currentColor" />
                  </motion.g>
                </motion.svg>
              )}
            </motion.div>
          </motion.button>
        </motion.nav>

        {/* ━━━ MOBILE MENU BUTTON - Bold Portal to Mobile Experience ━━━ */}
        <motion.button
          onClick={() => setIsOpen(!isOpen)}
          className={`
            md:hidden
            p-2.5
            rounded-md
            overflow-hidden
            relative
            ${colors.buttonBg}
            hover:${colors.buttonBgHover}
            focus:outline-none
            focus:ring-2
            ${
              theme === 'light'
                ? 'focus:ring-sky-400'
                : 'focus:ring-amber-400'
            }
            transition-all
            duration-200
            shadow-md
            ${colors.shadow}
            hover:${colors.shadowHover}
          `}
          aria-expanded={isOpen}
          aria-label={t('header.toggleMenu') || 'Open menu'}
          whileTap={whileTapDesktop}
        >
          {/* Dramatic Shimmer Animation */}
          <motion.div
            className={`
              absolute
              inset-0
              ${
                theme === 'light'
                  ? 'bg-gradient-to-r from-sky-200/0 via-sky-200/50 to-sky-200/0'
                  : 'bg-gradient-to-r from-amber-700/0 via-amber-700/30 to-amber-700/0'
              }
            `}
            animate={{
              x: ['-200%', '200%'],
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
              repeat: Infinity,
              repeatDelay: 4
            }}
          />
          
          {/* Bold Hamburger Icon Animation */}
          <div className="relative w-6 h-5">
            <motion.span
              className={`
                absolute
                left-0
                h-0.5
                w-full
                rounded-full
                ${colors.line}
                transform
                transition-all
                duration-300
              `}
              initial={{ top: "0%" }}
              animate={isOpen ? 
                { top: "50%", rotate: 45, y: "-50%" } : 
                { top: "0%", rotate: 0, y: 0 }
              }
            />
            <motion.span
              className={`
                absolute
                left-0
                top-1/2
                h-0.5
                w-full
                rounded-full
                ${colors.line}
                -translate-y-1/2
                transition-all
                duration-300
              `}
              animate={isOpen ? 
                { opacity: 0, x: 20 } : 
                { opacity: 1, x: 0 }
              }
            />
            <motion.span
              className={`
                absolute
                left-0
                h-0.5
                w-full
                rounded-full
                ${colors.line}
                transform
                transition-all
                duration-300
              `}
              initial={{ bottom: "0%" }}
              animate={isOpen ? 
                { bottom: "50%", rotate: -45, y: "50%" } : 
                { bottom: "0%", rotate: 0, y: 0 }
              }
            />
          </div>
        </motion.button>
      </div>

      {/* ━━━ MOBILE NAVIGATION MENU - Bold Mobile Experience ━━━ */}
      <AnimatePresence>
        {isOpen && (
          <motion.nav
            className={`
              md:hidden
              fixed
              top-0
              left-0
              w-full
              h-full
              z-40
              overflow-hidden
              ${
                theme === 'light'
                  ? dramaticLightGradient
                  : dramaticDarkGradient
              }
            `}
            initial="closed"
            animate="open"
            exit="closed"
            variants={menuVariants}
          >
            {/* Mobile Menu Backdrop */}
            <motion.div 
              className={`
                absolute 
                inset-0 
                z-0 
                pointer-events-none
                opacity-80
                ${
                  theme === 'light'
                    ? 'bg-gradient-to-br from-sky-50 via-white to-blue-50'
                    : ''
                }
              `}
            >
              {/* Dynamic Background Elements for Mobile */}
              {theme === 'light' && (
                <>
                  <motion.div
                    className="absolute -top-32 -right-32 w-96 h-96 rounded-full bg-gradient-to-br from-sky-100/30 via-white/0 to-transparent opacity-40 blur-2xl"
                    animate={{
                      scale: [1, 1.05, 1],
                      opacity: [0.4, 0.5, 0.4],
                    }}
                    transition={{
                      duration: 8,
                      ease: "easeInOut",
                      repeat: Infinity,
                      repeatType: "reverse"
                    }}
                  />
                  <motion.div
                    className="absolute -bottom-32 -left-32 w-96 h-96 rounded-full bg-gradient-to-br from-blue-100/20 via-white/0 to-transparent opacity-30 blur-2xl"
                    animate={{
                      scale: [1, 1.1, 1],
                      opacity: [0.3, 0.4, 0.3],
                    }}
                    transition={{
                      duration: 10,
                      ease: "easeInOut",
                      repeat: Infinity,
                      repeatType: "reverse",
                      delay: 1
                    }}
                  />
                </>
              )}
            </motion.div>

            {/* Mobile Menu Content Container */}
            <motion.div 
              className="
                container 
                mx-auto 
                px-6 
                py-10 
                h-full
                flex
                flex-col
                relative 
                z-10
              "
            >
              {/* Mobile Menu Header */}
              <div className="flex items-center justify-between mb-10">
                {/* Mobile Logo */}
                <motion.div 
                  className="flex items-center"
                  variants={menuItemVariants}
                >
                  <motion.img
                    src={theme === 'light' ? '/images/logo.png' : '/images/logo30.png'}
                    alt="Logo Contable Tenerife"
                    className="h-10 w-auto"
                    style={{
                      filter: theme === 'light' 
                        ? 'drop-shadow(0 1px 2px rgba(0,0,0,0.1))' 
                        : 'drop-shadow(0 1px 3px rgba(0,0,0,0.2))'
                    }}
                    animate={{
                      rotate: [0, 1, 0, -1, 0],
                    }}
                    transition={{
                      duration: 6,
                      ease: "easeInOut",
                      repeat: Infinity,
                      repeatType: "reverse"
                    }}
                  />
                  <div className="flex flex-col ml-3">
                    <span
                      className={`
                        text-2xl
                        font-bold
                        ${
                          theme === 'light'
                            ? 'text-gray-900'
                            : 'text-white'
                        }
                      `}
                    >
                      Contable{' '}
                      <span
                        className={theme === 'light' ? colors.primary : colors.accent}
                      >
                        Tenerife
                      </span>
                    </span>
                    <span
                      className={`
                        text-xs
                        font-medium
                        ${
                          theme === 'light'
                            ? 'text-gray-500'
                            : 'text-gray-300'
                        }
                      `}
                    >
                      {t('header.professionalAccounting')}
                    </span>
                  </div>
                </motion.div>

                {/* Bold Close Button */}
                <motion.button
                  onClick={() => setIsOpen(false)}
                  className={`
                    p-2.5
                    rounded-md
                    relative
                    overflow-hidden
                    ${colors.buttonBg}
                    hover:${colors.buttonBgHover}
                    focus:outline-none
                    focus:ring-2
                    ${
                      theme === 'light'
                        ? 'focus:ring-sky-400'
                        : 'focus:ring-amber-400'
                    }
                    transition-all
                    duration-200
                    shadow-md
                    ${colors.shadow}
                  `}
                  aria-label={t('header.closeMenu') || 'Close menu'}
                  whileTap={whileTapDesktop}
                  variants={menuItemVariants}
                >
                  {/* Shimmer Animation */}
                  <motion.div
                    className={`
                      absolute
                      inset-0
                      ${
                        theme === 'light'
                          ? 'bg-gradient-to-r from-sky-200/0 via-sky-200/50 to-sky-200/0'
                          : 'bg-gradient-to-r from-amber-700/0 via-amber-700/30 to-amber-700/0'
                      }
                    `}
                    animate={{
                      x: ['-200%', '200%'],
                    }}
                    transition={{
                      duration: 2,
                      ease: "easeInOut",
                      repeat: Infinity,
                      repeatDelay: 4
                    }}
                  />
                  
                  {/* Bold X Icon */}
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="24" 
                    height="24" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor"
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                    className={`relative z-10 ${colors.icon}`}
                  >
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
                </motion.button>
              </div>

              {/* Mobile Menu Content */}
              <div className="flex-1 flex flex-col justify-center">
                {/* Mobile Language Switcher - Also fixed to remove motion wrapper */}
                <motion.div
                  variants={menuItemVariants}
                  className="mb-10 flex justify-center"
                  style={{ zIndex: 9999 }}
                >
                  <LanguageSwitcher onLanguageChange={() => setIsOpen(false)} />
                </motion.div>

                {/* Bold Mobile Navigation Links */}
                <div className="space-y-4 mb-12">
                  {navItems.map((item) => (
                    <motion.div 
                      key={item.path} 
                      variants={menuItemVariants}
                      className="w-full"
                    >
                      <Link
                        to={item.path}
                        className={`
                          block
                          w-full
                          py-4
                          text-center
                          text-2xl
                          font-bold
                          tracking-wide
                          rounded-lg
                          transition-all
                          duration-300
                          relative
                          overflow-hidden
                          ${
                            theme === 'light'
                              ? location.pathname === item.path
                                ? `${colors.backgroundHover} ${colors.navActive} shadow-md`
                                : `${colors.navText} hover:${colors.navTextHover} hover:${colors.backgroundAccent}`
                              : location.pathname === item.path
                                ? `${colors.backgroundHover} ${colors.navActive} shadow-md`
                                : `${colors.navText} hover:${colors.navTextHover} hover:${colors.backgroundHover}`
                          }
                        `}
                        onClick={() => setIsOpen(false)}
                        aria-current={location.pathname === item.path ? 'page' : undefined}
                      >
                        {/* Active Item Shimmer Effect */}
                        {location.pathname === item.path && (
                          <motion.div
                            className={`
                              absolute
                              inset-0
                              ${
                                theme === 'light'
                                  ? 'bg-gradient-to-r from-sky-200/0 via-sky-200/30 to-sky-200/0'
                                  : 'bg-gradient-to-r from-amber-700/0 via-amber-700/20 to-amber-700/0'
                              }
                            `}
                            animate={{
                              x: ['-200%', '200%'],
                            }}
                            transition={{
                              duration: 3,
                              ease: "easeInOut",
                              repeat: Infinity,
                              repeatDelay: 2
                            }}
                          />
                        )}
                        
                        {item.name}
                      </Link>
                    </motion.div>
                  ))}
                </div>

                {/* Bold Mobile Theme Toggle */}
                <motion.div
                  className="flex items-center justify-center"
                  variants={menuItemVariants}
                >
                  <motion.button
                    onClick={() => {
                      toggleTheme();
                      setIsOpen(false);
                    }}
                    className={`
                      p-4
                      rounded-full
                      relative
                      overflow-hidden
                      ${colors.buttonBg}
                      ${colors.icon}
                      hover:${colors.buttonBgHover}
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-offset-white
                      dark:focus:ring-offset-gray-900
                      ${
                        theme === 'light'
                          ? 'focus:ring-sky-400'
                          : 'focus:ring-amber-400'
                      }
                      transition-all
                      duration-300
                      shadow-lg
                      ${colors.shadow}
                    `}
                    aria-label={t('header.toggleTheme') || 'Change theme'}
                    whileHover={whileHoverDesktop}
                    whileTap={whileTapDesktop}
                    variants={themeToggleVariants}
                    animate={theme === 'light' ? 'light' : 'dark'}
                  >
                    {/* Shimmer Animation */}
                    <motion.div
                      className={`
                        absolute
                        inset-0
                        ${
                          theme === 'light'
                            ? 'bg-gradient-to-r from-sky-200/0 via-sky-200/50 to-sky-200/0'
                            : 'bg-gradient-to-r from-amber-700/0 via-amber-700/30 to-amber-700/0'
                        }
                      `}
                      animate={{
                        x: ['-200%', '200%'],
                      }}
                      transition={{
                        duration: 3,
                        ease: "easeInOut",
                        repeat: Infinity,
                        repeatDelay: 3
                      }}
                    />
                    
                    {/* Theme Icon with Bold Transition */}
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      key={theme}
                      transition={{ duration: 0.5 }}
                      className="relative z-10"
                    >
                      {theme === 'light' ? (
                        // Bold Sun Icon Animation
                        <motion.svg
                          xmlns="http://www.w3.org/2000/svg"
                          key="sun"
                          width="28"
                          height="28"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          animate={{
                            rotate: [0, 180, 360],
                          }}
                          transition={{
                            duration: 40,
                            ease: "linear",
                            repeat: Infinity
                          }}
                        >
                          <circle cx="12" cy="12" r="5" />
                          <motion.g
                            animate={{
                              scale: [1, 1.1, 1],
                              opacity: [0.8, 1, 0.8]
                            }}
                            transition={{
                              duration: 3,
                              ease: "easeInOut",
                              repeat: Infinity,
                              repeatType: "reverse"
                            }}
                          >
                            <line x1="12" y1="1" x2="12" y2="3" />
                            <line x1="12" y1="21" x2="12" y2="23" />
                            <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
                            <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
                            <line x1="1" y1="12" x2="3" y2="12" />
                            <line x1="21" y1="12" x2="23" y2="12" />
                            <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
                            <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
                          </motion.g>
                        </motion.svg>
                      ) : (
                        // Dramatic Moon Icon Animation
                        <motion.svg
                          xmlns="http://www.w3.org/2000/svg"
                          key="moon"
                          width="28"
                          height="28"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M21 12.79A9 9 0 1 1 11.21 3a7 7 0 0 0 9.79 9.79z" />
                          <motion.g
                            animate={{
                              opacity: [0, 1, 0],
                            }}
                            transition={{
                              duration: 3,
                              ease: "easeInOut",
                              repeat: Infinity,
                              repeatType: "reverse",
                              delay: 1,
                              repeatDelay: 2
                            }}
                          >
                            <circle cx="7.5" cy="5.5" r="0.5" fill="currentColor" />
                            <circle cx="19" cy="10" r="0.5" fill="currentColor" />
                            <circle cx="17" cy="6" r="0.5" fill="currentColor" />
                          </motion.g>
                        </motion.svg>
                      )}
                    </motion.div>
                  </motion.button>
                </motion.div>
              </div>

              {/* Bold Decorative Corner Elements in Mobile */}
              {theme === 'light' && (
                <>
                  <motion.div 
                    className="absolute top-0 right-0 w-32 h-32 opacity-30 pointer-events-none"
                    variants={menuItemVariants}
                  >
                    <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 0L100 0L100 100" stroke="rgba(14, 165, 233, 0.3)" strokeWidth="2" fill="none"/>
                    </svg>
                  </motion.div>
                  <motion.div 
                    className="absolute bottom-0 left-0 w-32 h-32 opacity-30 pointer-events-none"
                    variants={menuItemVariants}
                  >
                    <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 100L0 100L0 0" stroke="rgba(14, 165, 233, 0.3)" strokeWidth="2" fill="none"/>
                    </svg>
                  </motion.div>
                </>
              )}
            </motion.div>
          </motion.nav>
        )}
      </AnimatePresence>

      {/* ━━━ DRAMATIC DECORATIVE ELEMENTS - Bold Artistic Flourishes ━━━ */}
      {theme === 'light' && (
        <>
          {/* Top edge blue highlight */}
          <motion.div
            className="
              absolute
              left-0
              right-0
              top-0
              h-[1px]
              bg-gradient-to-r
              from-transparent
              via-sky-300
              to-transparent
              opacity-60
              pointer-events-none
            "
          />
          
          {/* Bottom edge subtle accent */}
          <motion.div
            className="
              absolute
              left-0
              right-0
              bottom-0
              h-[1px]
              bg-gradient-to-r
              from-transparent
              via-sky-200/40
              to-transparent
              opacity-30
              pointer-events-none
            "
          />

          {/* Dramatic corner decorations */}
          <div className="absolute top-0 right-0 w-12 h-12 overflow-hidden opacity-30 pointer-events-none">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0L48 0L48 48" stroke="url(#paint0_linear)" strokeWidth="1.5"/>
              <defs>
                <linearGradient id="paint0_linear" x1="24" y1="0" x2="24" y2="48" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#0EA5E9"/>
                  <stop offset="1" stopColor="#0EA5E9" stopOpacity="0"/>
                </linearGradient>
              </defs>
            </svg>
          </div>
          
          <div className="absolute bottom-0 left-0 w-12 h-12 overflow-hidden opacity-30 pointer-events-none">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M48 48L0 48L0 0" stroke="url(#paint0_linear)" strokeWidth="1.5"/>
              <defs>
                <linearGradient id="paint0_linear" x1="24" y1="48" x2="24" y2="0" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#0EA5E9"/>
                  <stop offset="1" stopColor="#0EA5E9" stopOpacity="0"/>
                </linearGradient>
              </defs>
            </svg>
          </div>
        </>
      )}
    </motion.header>
  );
};

export default Header;